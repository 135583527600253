<template>
    <div class="gift">
      <leftNav></leftNav>
      <div class="giftcontent">
          <div class="gift_top">
              <p class="title">我的订单</p>
          </div>
          <div class="gift_bottom">
              <el-table :data="dataList" >
                <el-table-column type='index' label="序号" :index="indexMethod" width="55"> </el-table-column>
                 <el-table-column label="订单编号" prop="orderNo" align="center"></el-table-column>
                 <el-table-column label="订单名称" prop="orderName" align="center"></el-table-column>
                 <el-table-column label="下单时间" prop="createTime" align="center"></el-table-column>
                 <el-table-column label="订单金额" prop="orderAmount" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.orderAmount}}元</span>
                    </template>
                 </el-table-column>
                 <el-table-column label="订单状态" prop="orderStatus" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.orderStatus==0 || scope.row.orderStatus==-1" style="color: #FF8240;">未完成</span>
                        <span v-else style="color: #1DB828;">已完成</span>
                    </template>
                 </el-table-column>
                 <el-table-column label="操作" prop="deadline" align="center">
                    <template slot-scope="scope">
                        <el-button  type="text" @click="toDetail(scope.row)">查看详情</el-button>
                    </template>
                 </el-table-column>
              </el-table>
              <paging :pageall="pageall" :pagenum="pageNum" @pageclick="pageclick" style="margin-top: 20px"></paging>
          </div>
      </div>

     </div>
  </template>

  <script>
  import leftNav from "./leftNav";
  import { getOrderlist } from "api/index.js";
  import paging from '@/components/paging.vue';
  export default {
      components: {
          leftNav,
          paging
      },
      data() {
          return {
              dataList: [],
              pageNum: 1,
              pageSize: 10,
              pageall: 0,
          };
      },
      mounted() {
        this.getOrder();
      },
      methods: {
          getOrder() {
            const data = {
                cmsUserId: localStorage.getItem('userInfo').id,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            };
            getOrderlist(data).then(res => {
              this.dataList = res.data.list;
              this.pageall = res.data.total || 0;
            });
          },
          pageclick(num) {
            this.pageNum = num;
            this.getOrder();
        },
        indexMethod(index) {
            return (index + 1) + (this.pageNum - 1) * this.pageSize;
        },
        toDetail(row) {
            this.$router.push({
                path: "/myOrderDetail",
                query: {
                    id: row.id,
                }
            });
        }
      }
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';
  .gift{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color:#F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
  }
  .giftcontent{
      width 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .gift_top{
          padding:20px;

          .title{
              font-size: 20px;
              color: #333333;
              height:26px;
              position relative;
              padding-left: 14px;
              font-weight: bold;
          }
          .title::after{
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width :4px;
            height :20px;
            display: block;
            background-color: $themeColor;
            border-radius: 2px;
        }

      }
      .giftinput{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 27px auto 31px;
          .el-input{
              width: 330px;

          }
          >>>.el-input__inner{
              background-color: transparent;
              height:40px;
          }
          p{
              font-size: 14px;
              color: #666666;
          }
          .btn{
              width: 100px;
              height: 35px;
              background: #FF8240;
              border-radius: 21px 21px 21px 21px;
              font-size: 14px;
              color: #FFFFFF;
              margin-left: 10px;
              cursor: pointer;
              text-align: center;
              line-height: 35px;
          }
      }
  }
  .gift_bottom{
      padding: 0px 20px;
      p{
          font-size: 14px;
          color: #333333;
          margin-bottom: 15px;
          font-weight: bold;
      }
  }
  </style>
  <style>
   #app{
     background-color: #F5F7FA;
    }
  </style>