<template>
  <div class="gift">
    <leftNav></leftNav>
    <div class="giftcontent">
        <div class="gift_top">
            <p class="title">我的邀请码</p>
            <div class="giftcont">
                <div class="giftinput">
                    <p>输入礼包码获取礼包：</p>
                    <el-input
                        placeholder="请输入"
                        v-model="invitationCode">
                    </el-input>
                    <p class="btn" @click="getGift">兑换</p>
                </div>
            </div>
        </div>
        <div class="gift_bottom">
            <p>礼包记录</p>
            <el-table :data="dataList" >
               <el-table-column label="序号" align="center" width="100"></el-table-column>
               <el-table-column label="兑换礼包码" prop="name" align="center"></el-table-column>
               <el-table-column label="类型" prop="rank" align="center"></el-table-column>
               <el-table-column label="礼包内容" prop="majors" align="center"></el-table-column>
               <el-table-column label="绑定时间" prop="deadline" align="center"></el-table-column>
            </el-table>
        </div>
    </div>

   </div>
</template>

<script>
import leftNav from "./leftNav";
export default {
    components: {
        leftNav
    },
    data() {
        return {
            invitationCode: "",
            dataList: []

        };
    },
    methods: {
        getGift() {

        }
    }
};
</script>

<style  lang='stylus' scoped>
.gift{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color:#F5F7FA;
    padding-bottom: 60px;
}
.giftcontent{
    width 78%;
    background-color: #fff;
    margin: 27px 2% 27px;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    .gift_top{
        padding:20px;
        background: linear-gradient( 180deg, #FFF5E5 0%, rgba(255,255,255,0) 100%);
        .title{
            font-size: 20px;
            color: #333333;
            height:26px;
            position relative;
            padding-left: 14px;
        }
        .title::after{
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width :4px;
            height :20px;
            display: block;
            background-color: $themeColor;
            border-radius: 2px;
        }

    }
    .giftinput{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 27px auto 31px;
        .el-input{
            width: 330px;

        }
        >>>.el-input__inner{
            background-color: transparent;
            height:40px;
        }
        p{
            font-size: 14px;
            color: #666666;
        }
        .btn{
            width: 100px;
            height: 35px;
            background: #FF8240;
            border-radius: 21px 21px 21px 21px;
            font-size: 14px;
            color: #FFFFFF;
            margin-left: 10px;
            cursor: pointer;
            text-align: center;
            line-height: 35px;
        }
    }
}
.gift_bottom{
    padding: 0px 20px;
    p{
        font-size: 14px;
        color: #333333;
        margin-bottom: 15px;
        font-weight: bold;
    }
}
</style>
<style>
#app{
    background-color: #F5F7FA;
}
</style>