import request from '@utils/request';
// 获取学院
export function getAcademyList(data) {
  return request({
    url: '/mv/cmsCollege/list',
    method: 'post',
    data
  });
}
export function getcmsCollegedetail(data) {
  return request({
    url: '/mv/cmsCollege/detail',
    method: 'post',
    data
  });
}

