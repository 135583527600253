<template>
  <div class="professionalDetails">
    <headerTitle title="首页" :titles="['院校匹配', '专业列表', '项目详情']" :showBtn="true" :translate="true"></headerTitle>
    <div class="flex-center-column">
      <!--  -->
      <boxHead :schoolId="schoolId" :Id="Id" :isContrast="isContrast" ></boxHead>
      <div class="flexBetween detailBox">
        <!--  -->
        <div class="banner-box">
          <!-- <el-menu text-color="#333333" active-text-color="$themeColor" :default-active="activeMenu" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" v-show="setMenu.ZYJS.isShow==1">{{setMenu.ZYJS.showName}}</el-menu-item>
            <el-menu-item index="2" v-show="setMenu.BJJS.isShow==1">{{ setMenu.BJJS.showName }}</el-menu-item>
            <el-menu-item index="3" v-show="setMenu.ZYXGXX.isShow==1">{{ setMenu.ZYXGXX.showName }}</el-menu-item>
            <el-menu-item index="4" v-show="setMenu.KC.isShow==1">{{ setMenu.KC.showName }}</el-menu-item>
          </el-menu> -->
          <div id="content-1" >
            <p class="banner-box-title">
              <span></span>
              学院介绍
            </p>
            <span>
              <p class="links">学院名称：<span>{{ dataInfo.collegeNameCn }} | {{ dataInfo.collegeNameEn }}</span></p>
              <p class="links">项目名称：<span>{{ dataInfo.programNameCn }} | {{ dataInfo.programNameEn }}</span></p>
              <p><span style="margin-right: 20px;" v-for="(item,index) in dataInfo.majors" :key="index">{{ item.name }}</span></p>
            </span>
          </div>
          <div id="content-2"  v-if="dataInfo.introdutionCn">
            <p class="banner-box-title">
              <span></span>
              简介
            </p>
            <span>
              {{ dataInfo.introdutionCn }}
            </span>
          </div>
          <div id="content-2"  v-if="dataInfo.backgroundRequire">
            <p class="banner-box-title">
              <span></span>
              背景要求
            </p>
            <span>
              {{ dataInfo.backgroundRequire }}
            </span>
          </div>
          <div id="content-3" >
            <p class="banner-box-title">
              <span></span>
              专业相关信息
            </p>
            <div class="descriptionBox float">
              <p v-if="dataInfo.programCataglog">项目细分领域：{{ dataInfo.programCataglog }}</p>
              <p v-if="dataInfo.lengthOfStudy">项目学习时长(以月计)：{{ dataInfo.lengthOfStudy }}</p>
              <p v-if="dataInfo.majors && dataInfo.majors.length!=0">该项目所属专业：<font style="margin-right: 10px;" v-for="(item,index) in dataInfo.majors" :key="index">{{ item.name }}</font></p>
              <p v-if="dataInfo.department">该项目所属系：{{ dataInfo.department }}</p>
              <p v-if="dataInfo.subdivision">细分方向(英文)：{{ dataInfo.subdivision }}</p>
              <p v-if="dataInfo.lettersOfRecommendation">推荐信数量要求：{{ dataInfo.lettersOfRecommendation }}</p>
            </div>
            <!-- <descriptions :descriptionsInfo="dataInfo"></descriptions> -->
          </div>
          <div id="content-3" >
            <p class="banner-box-title">
              <span></span>
              专业要求信息
            </p>
            <div class="descriptionBox">
              <p v-if="dataInfo.admissionInfoUrl">该项目申请要求的entrypoint链接: {{ dataInfo.admissionInfoUrl }}</p>
              <p v-if="dataInfo.documentLink"><span @click="toLink(dataInfo.documentLink)">文书要求</span></p>
              <p v-if="dataInfo.psNum">Ps:需要{{ dataInfo.psNum }}篇 具体要求:{{ dataInfo.ps }}</p>
              <p v-if="dataInfo.sopNum">Sop:需要{{dataInfo.sopNum}}篇 具体要求:{{ dataInfo.sop }}</p>
              <p v-if="dataInfo.essayNum">Essay:需要{{ dataInfo.essayNum }}篇 具体要求:{{ dataInfo.essay }}</p>
              <p v-if="dataInfo.interviewLink"><span @click="toLink(dataInfo.interviewLink)">面/笔试要求: </span>{{ dataInfo.interview }}</p>
              <p v-if="dataInfo.workingExperience"><span @click="toLink(dataInfo.interviewLink)">工作要求: </span>{{ dataInfo.workingExperience }}</p>
              <p v-if="dataInfo.sampleReels"><span @click="toLink(dataInfo.interviewLink)">作品集:</span>{{ dataInfo.sampleReels }}</p>
              <p v-if="dataInfo.gpa"><span @click="toLink(dataInfo.gpaLink)">GPA：</span>要求总分达到：{{dataInfo.gpa}}分</p>
              <p v-if="dataInfo.ieltsRequire"><span @click="toLink(dataInfo.ieltsLink)">雅思：</span><font v-if="dataInfo.ieltsRequire!=1">{{matching(dataInfo.ieltsRequire)}}总分达到{{dataInfo.ieltsTotal}}分，单项最低分达到{{dataInfo.ieltsLow}}分，具体需求：{{ dataInfo.ielts }}</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.toeflRequire"><span @click="toLink(dataInfo.toeflLink)">托福：</span><font v-if="dataInfo.toeflRequire!=1">{{matching(dataInfo.toeflRequire)}}总分达到{{dataInfo.toeflTotal}}分，单项最低分达到{{dataInfo.toeflLow}}分，具体需求：{{ dataInfo.toefl }}；</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.greRequire"><span @click="toLink(dataInfo.greLink)">GRE：</span><font v-if="dataInfo.greRequire!=1">{{matching(dataInfo.greRequire)}}总分达到{{dataInfo.greTotal}}分，GRE Verbal达到{{dataInfo.greVerbal}}分，GRE  mathematics达到{{dataInfo.greMaths}}分，GRE  writing达到{{dataInfo.greWriting}}分，具体需求：{{dataInfo.gre}}；</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.duolingoRequire"><span @click="toLink(dataInfo.duolingoLink)">多领国：</span><font v-if="dataInfo.duolingoRequire!=1">{{matching(dataInfo.duolingoRequire)}}总分达到{{dataInfo.duolingoTotal}}分，单项最低分达到{{dataInfo.duolingoLow}}分，具体需求：{{dataInfo.duolingo}}；</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.gmatRequire"><span @click="toLink(dataInfo.gmatLink)">GMAT：</span><font v-if="dataInfo.gmatRequire!=1">{{matching(dataInfo.gmatRequire)}}总分达到{{dataInfo.gmatTotal}}分，单项最低分达到{{dataInfo.gmatLow}}分，具体需求：{{dataInfo.gmat}}；</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.lsatRequire"><span @click="toLink(dataInfo.lsatLink)">LSAT：</span><font v-if="dataInfo.lsatRequire!=1">{{matching(dataInfo.lsatRequire)}}总分达到{{dataInfo.lsatTotal}}分，单项最低分达到{{dataInfo.lsatLow}}分，具体需求：{{dataInfo.lsat}}；</font><font v-else>不要求</font></p>
            </div>
            <div class="tipBox" >
              <p class="titles" @click="toLink(dataInfo.applicationDeadlineInfoUrl)">申请截止日</p>
              <p>
                <span>申请截止日-春季入学:{{ dataInfo.applicationDeadlineSpring }}</span> <span>申请截止日-夏季入学:{{ dataInfo.applicationDeadlineSummer }}</span> <span>申请截止日-秋季入学:{{dataInfo.applicationDeadlineFall}}</span>
              </p>
              <p v-if="dataInfo.tuitionLink" class="titles" @click="toLink(dataInfo.tuitionLink)">1{{matchTxt(dataInfo.tuitionType)}}学费为{{ dataInfo.tuition }}美元</p>
            </div>
            <!-- <descriptions :descriptionsInfo="dataInfo"></descriptions> -->
          </div>
          <div id="content-4"  v-show="setMenu.KC.isShow==1">
            <p class="banner-box-title" v-if="dataInfo.curriculum && dataInfo.curriculum.length>0">
              <span></span>
              课程
            </p>
            <el-table v-if="dataInfo.curriculum && dataInfo.curriculum.length>0" stripe :header-cell-style="rowClass"   :data="dataInfo.curriculum" style="width: 100%" border >
              <el-table-column label="课程名称 （Curriculum）" align="center">
                <el-table-column label="" prop="cn" align="center" show-overflow-tooltip min-width="100"> </el-table-column>
                <el-table-column label="" prop="en" align="center" show-overflow-tooltip min-width="150"></el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--  -->
        <!-- <div class="box-right"  v-show="setMenu.AL.isShow==1">
          <div>
            <p class="banner-box-title" style="padding-top: 20px">
              <span></span>
              案例分析
            </p>
            <p>{{ caseData.caseAnalysis || '本专业暂无成功案例。该专业暂无收录之案例' }}</p>
            <el-button class="case" @click="toDetail" round>查看案例</el-button>
          </div>
          <div >
            <p class="banner-box-title">
              <span></span>
              院校背景分析
            </p>
            <collegeBackground :chartData="caseData.schoolAnalysis"></collegeBackground>
          </div>
          <div >
            <p class="banner-box-title">
              <span></span>
              专业背景分析
            </p>
            <majorBackground :chartData="caseData.majorAnalysis"></majorBackground>
          </div>
          <div style="width:100%">
            <p class="banner-box-title">
              <span></span>
              分数分析
            </p>
            <score :chartData="caseData.scoreAnalysis"></score>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import boxHead from './components/boxHead.vue';
// import collegeBackground from './components/collegeBackground.vue';
// import majorBackground from './components/majorBackground.vue';
// import score from './components/score.vue';
// import descriptions from './components/descriptions.vue';
import { getcmsProgram, analysisCaseInfo } from 'api/libraryList.js';
import { getmenuList } from 'api/menu.js';

export default {
  name: 'ProfessionalDetails',
  components: { headerTitle, boxHead },
  // collegeBackground, majorBackground, score, descriptions
  data() {
    return {
      schoolId: '',
      Id: '',
      activeMenu: '1', // 默认激活的菜单项
      dataInfo: {},
      caseData: {
        scoreAnalysis: [],
        majorAnalysis: [],
        schoolAnalysis: [],
      },
      setMenu: {
        ZYJS: { isShow: 0 },
        BJJS: { isShow: 0 },
        ZYXGXX: { isShow: 0 },
        KC: { isShow: 0 },
        AL: { isShow: 1 },
      },
      isContrast: true,
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    rowClass({ row, column, rowIndex, columnIndex }) {
        if (rowIndex === 0 && columnIndex === 0) {
          this.$nextTick(() => {
            if (document.getElementsByClassName(column.id).length !== 0) {
              document.getElementsByClassName(column.id)[0].setAttribute('rowSpan', 2);
              return false;
            }
          });
        }
        if (rowIndex === 1 && (columnIndex === 0 || columnIndex === 1)) {
          return { display: 'none' };
        }
      },
    getMajorDetail() {
      getcmsProgram({
        id: this.$route.query.id
      }).then(res => {
        if (res.code == '2000') {
          this.dataInfo = res.data;
          if (this.dataInfo.curriculum) {
            this.dataInfo.curriculum = JSON.parse(this.dataInfo.curriculum);
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    toDetail() {
      this.$router.push({
        path: '/caseDetails',
      });
    },
    toLink(val) {
      if (val !== '' && val !== null) {
        window.open(val);
      }
    },
    handleSelect(index) {
      const element = document.getElementById(`content-${index}`);
      if (element) {
        const scrollTopValue = element.offsetTop - 25;
        // element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: scrollTopValue,
          behavior: 'smooth' // 平滑滚动
        });
      }
    },
    analysisCaseInfo() {
      analysisCaseInfo({
        majorId: this.$route.query.id
      }).then(res => {
        if (res.code == '2000') {
          this.caseData = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getMenu() {
      const data = {
        code: 'ZY'
      };
      getmenuList(data).then(res => {
            if (res.code == 2000) {
                // this.$store.commit('setMenu',res.data)
                this.setMenu = res.data;
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: err.msg
            });
        });
    },
    matching(val) {
      return val == 0 ? '要求' : val == 1 ? '不要求' : val == 2 ? '建议' : val == 3 ? '可选择' : '';
    },
    matchTxt(val) {
      return val == 0 ? '学期' : val == 1 ? '学分' : val == 2 ? '学年' : '';
    }
  },
  mounted() {
    this.schoolId = this.$route.query.schoolId;
    this.Id = this.$route.query.id;
    this.getMajorDetail();
    // this.analysisCaseInfo();
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.professionalDetails {
  width: 100%;
  background: #F5F7FA url('../../assets/professionalList.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .detailBox {
    width: $width;
    line-height: 26px;
  }

  .banner-box {
    height: 100%;
    line-height: 26px;
    padding-bottom: 28px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    padding: 0 20px 20px;

    .banner-box-item {
      padding-bottom: 20px;

      p {
        font-size: 20px;
      }
    }
  }

  .banner-box-title {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 32px;
    padding: 30px 0 20px;
    display: flex;
    align-items: center;

    &>span {
      line-height: 32px;
      display: inline-block;
      color: $themeColor;
      width: 4px;
      height: 20px;
      background: $themeColor;
      border-radius: 90px 90px 90px 90px;
      margin-right: 8px;
    }
  }

  .box-right {
    border-radius: 12px 12px 12px 12px;
    width: 403px;
    background: #FFFFFF;
    padding: 0 20px 20px;
    box-sizing: border-box;
  }

  .case {
    width: 364px;
    height: 40px;
    background: $themeColor;
    color: #fff;
    margin-top: 20px;
  }
}

/deep/ .el-menu-item {
  font-size: 18px;
  padding: 0;
  margin: 0 50px;
}

/deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 4px solid #409EFF;
}
.links{
  margin-bottom:10px;
}
.links>span{
  font-weight: 400;
  font-size: 14px;
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}
.descriptionBox{
  p{
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 15px;
    span{
      color: #409EFF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.float>p{
  width: 33%;
  float: left;
}
.float:after{
  content: "";
  display: block;
  clear: both;
}
.tipBox{
  margin: 20px 0 0;
  .titles{
    font-size: 14px;
    color: $themeColor;
    line-height: 26px;
    text-decoration-line: underline;
    font-weight: bold;
    cursor: pointer;
  }
  p{
    span{
      display: inline-block;
      margin-right: 60px;
      font-size: 14px;
      color: #333333;
    }
  }
  p:nth-of-type(2){
    font-size: 12px;
    color: #333333;
    margin: 15px 0 20px;
   }
}
</style>