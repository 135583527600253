<template>
  <div class="pagingbigbox" v-if="!(pageall <= pagesize)" :style="comStyle">
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="pagenum"
      :page-size="pagesize"
      :layout="layout"
      :total="pageall"
      :hide-on-single-page="false"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "Paging",
  props: {
    pageall: {
      type: Number,
      default: 0
    },
    pagenum: {
      type: Number,
      required: true
    },
    pagesize: {
      type: Number,
      default: 10
    },
    zoom: { // 小于1缩小，大于1放大
      type: Number,
      default: () => {
        return 1;
      }
    },
    layout: { // 加减功能----只要又不需要的功能参数，都要传这个值
      type: String,
      default: () => {
        return 'total, prev, pager, next, jumper';
      }
    }
  },
  methods: {
    // 点击分页
    handleCurrentChange(val) {
      this.$emit("pageclick", val);
    }
  },
  computed: {
    comStyle() {
      let str = '';
      if (this.zoom) {
        str += `transform: scale(${this.zoom})`;
      }
      return str;
    }
  }
};
</script>
<style lang="stylus" scoped>
.pagingbigbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  >>> .el-pagination {
    display: flex;
    align-items: center;

    .el-pager {
      display: flex;
      align-items: center;
    }

    .el-pager li {
      padding: 0 6px;
      box-sizing: border-box;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      font-size: 12px;
    }

    .el-pager .active {
      color: #ffffff;
      background: rgba(85, 116, 216, 1);
      border-color: rgba(85, 116, 216, 1);
    }
    .el-pager li:hover{
      color: rgba(85, 116, 216, 1);
    }
    .el-pager li.active:hover{
      color:#fff
    }
    .pagingbigbox[data-v-7a6788c8] .el-pagination .el-pager .active {}
    .btn-next, .btn-prev {
      padding: 0 6px;
      box-sizing: border-box;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
    }

    .el-input__inner {
      padding: 6px;
      box-sizing: border-box;
      height: auto;
    }

    .el-pagination__jump {
      height: auto;
    }

    .el-pagination__editor {
      height: auto;
    }
  }
}
</style>