var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.show,"width":"80%","show-close":false,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"headerBigBox"},[_c('div',{staticClass:"headerText"},[_vm._v("项目排名对比")]),_c('div',{staticClass:"headerButtonBox"},[_c('div',{staticClass:"headerButton",on:{"click":_vm.cancelClick}},[_c('i',{staticClass:"el-icon-close"})])])])]),_c('div',{staticClass:"searchBigBox"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.searhObj}},[_c('el-form-item',{attrs:{"label":"排名年份："}},[_c('el-select',{attrs:{"placeholder":"请选择排名年份"},on:{"change":_vm.yearChange},model:{value:(_vm.searhObj.rankYear),callback:function ($$v) {_vm.$set(_vm.searhObj, "rankYear", $$v)},expression:"searhObj.rankYear"}},_vm._l((_vm.yearList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.name}})}),1)],1),_c('el-form-item',[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v(" 提示：如果切换年份将会清空列表数据! ")])])],1)],1),_c('div',{staticClass:"contentBigBox"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.comparisonList,"stripe":"","row-key":"id"}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"prop":"name","label":"学校名称","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"filterable":"","remote":"","placeholder":"请输入学校名称","remote-method":(name) => {
                  _vm.schoolRemoteMethod(scope.row, name);
                },"loading":scope.row.schoolLoading},on:{"change":(id) => {
                  _vm.schoolChange(id, scope.$index);
                }},model:{value:(scope.row.schoolId),callback:function ($$v) {_vm.$set(scope.row, "schoolId", $$v)},expression:"scope.row.schoolId"}},_vm._l((scope.row.schoolList),function(item){return _c('el-option',{key:item.universityId,attrs:{"label":item.nameCn,"value":item.universityId}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"schoolRankCollect","label":"排名","align":"center","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"rankingBox"},_vm._l((scope.row.schoolRankCollect),function(item,index){return _c('div',{key:index,staticClass:"ranking"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"学院名称","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.schoolId)?_c('el-select',{attrs:{"filterable":"","remote":"","placeholder":"请输入学院名称","remote-method":(name) => {
                  _vm.collegeRemoteMethod(scope.row, name);
                },"loading":scope.row.collegeLoading},on:{"change":(id) => {
                  _vm.collegeChange(id, scope.$index);
                }},model:{value:(scope.row.collegeId),callback:function ($$v) {_vm.$set(scope.row, "collegeId", $$v)},expression:"scope.row.collegeId"}},_vm._l((scope.row.collegeList),function(item){return _c('el-option',{key:item.collegeId,attrs:{"label":item.nameCn,"value":item.collegeId}})}),1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"collegeRankCollect","label":"排名","align":"center","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"rankingBox"},_vm._l((scope.row.collegeRankCollect),function(item,index){return _c('div',{key:index,staticClass:"ranking"},[_vm._v(" "+_vm._s(item)+" ")])}),0)]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"项目名称","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.collegeId)?_c('el-select',{attrs:{"filterable":"","remote":"","placeholder":"请输入项目名称","remote-method":(name) => {
                  _vm.projectRemoteMethod(scope.row, name);
                },"loading":scope.row.projectLoading},on:{"change":(id) => {
                  _vm.projectChange(id, scope.$index);
                }},model:{value:(scope.row.projectId),callback:function ($$v) {_vm.$set(scope.row, "projectId", $$v)},expression:"scope.row.projectId"}},_vm._l((scope.row.projectList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.programNameCn,"value":item.id}})}),1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"排名","align":"center","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"rankingBox"},_vm._l((scope.row.majorsList),function(item,index){return _c('div',{key:index,staticClass:"ranking"},[_vm._v(" "+_vm._s(item.majorRankStr)+" ")])}),0)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"90","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"#FF8240"},attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteOnceList(scope.$index)}}},[_vm._v(" 移除 ")])]}}])})],1),_c('div',{staticClass:"addListButton",on:{"click":_vm.addOnceList}},[(_vm.searhObj.rankYear)?_c('i',{staticClass:"el-icon-plus",staticStyle:{"font-weight":"bold","font-size":"18px","color":"#FF8240"}}):_c('span',[_vm._v("请先选择排名年份")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }