import Vue from 'vue';
import Vuex from 'vuex';
import aside from './modules/aside';
import verification from './verification';
import request from '@utils/request';
Vue.use(Vuex);

// const user = { // 重命名模块以避免与state属性冲突
//   state: {
//     info: {} // 重命名属性以避免与模块名冲突
//   },
//   mutations: {
//     setUserInfo(state, value) {
//       state.info = value; // 更新属性名以匹配重命名
//     }
//   },
//   actions: {
//     setUserInfo({ commit }, value) {
//       commit('setUserInfo', value); // 更新属性名以匹配重命名
//     }
//   }
// };
export default new Vuex.Store({
  modules: {
    aside,
    verification,
    // user
  },
  mutations: {
    TRACK_EVENT(state, payload) {
      // 处理埋点事件
      // 这里可以替换为实际的埋点代码

    }
  },
  actions: {
    trackEvent({ commit }, payload) {
      commit('TRACK_EVENT', payload);
      console.log('埋点事件', payload.action);
      request({
        url: '/mv/cmsBuryingPoint/add',
        method: 'post',
        data: {
          name: payload.action
        }
      });
    }
  }
});
