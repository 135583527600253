
import request from '@utils/request';

// 我收藏的学校
export function mySchoolCollection(data) {
  return request({
    url: '/mv/cmsUserCollection/myUniversityCollection',
    method: 'post',
    data
  });
}
// 我收藏的专业
export function myMajorCollection(data) {
    return request({
      url: '/mv/cmsUserCollection/myProgramCollection',
      method: 'post',
      data
    });
  }