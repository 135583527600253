<template>
  <div class="verificationBox">
    <!-- 登录 -->
    <el-dialog title="" :close-on-click-modal="false" :visible.sync="verificationFlag" width="30%" :before-close="closeClick">
      <div>
        <div class="tabBox finger flexAroundCenter">
          <!-- <p @click="activeIndex = 0" :class="activeIndex == 0 ? 'active' : ''">扫码登录</p> -->
          <p @click="activeIndex = 1" :class="activeIndex == 1 ? 'active' : ''">手机号登录</p>
        </div>
        <div class="codeBox flex-center-column" v-show="activeIndex == 0">
          <div id="qrCode" ref="qrCodeDiv"></div>
          <p>使用微信扫一扫登录</p>
          <el-checkbox v-model="checked">同意用户协议及隐私协议</el-checkbox>
        </div>
        <div class="mobileBox" v-show="activeIndex == 1">
          <el-form :rules="rules" :model="formInfo" ref="formInfo" label-width="100px" class="demo-ruleForm">
            <el-form-item label="" prop="mobile">
              <img src="@/assets/mobile.png" alt="" />
              <el-input type="number" v-model.trim="formInfo.mobile" placeholder="手机号码"></el-input>
            </el-form-item>
            <el-form-item label="" prop="code">
              <img src="@/assets/codes.png" alt="" />
              <el-input type="number" v-model.trim="formInfo.verifyCode" placeholder="验证码"></el-input>
              <el-button type="text" @click="getCode" :disabled="disabledFlag">{{ codeText }}</el-button>
            </el-form-item>
            <el-form-item label="" prop="checked" :rules="[{ required: true, message: '同意用户协议及隐私协议', trigger: ['blur', 'change'] }]">
              <el-checkbox v-model="formInfo.checked">同意用户协议及隐私协议</el-checkbox>
            </el-form-item>
          </el-form>
          <div>
            <el-button type="primary" class="login-text" round @click="toLogin('formInfo')">登录</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--创建个人信息  -->
    <el-dialog title="创建个人信息" :close-on-click-modal="false" :show-close="false" :visible.sync="userFlag" width="30%" :before-close="closeClick">
      <el-form ref="userFormData" :model="userFormData" label-width="100px">
        <el-form-item label="用户头像：">
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="uploadSectionFile" accept=".jpg,.jpeg" :limit="1" :multiple="false" :on-remove="handleRemove" :file-list="userFormData.imageUrl ? [{ name: '用户头像', url: userFormData.imageUrl }] : []">
            <img v-if="userFormData.headLogo" :src="userFormData.headLogo" class="avatar" />
            <p v-else class="avatar-uploader-text">上传头像</p>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户昵称：">
          <el-input v-model="userFormData.name" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="我的学校：">
          <!-- <el-select v-model="userFormData.schoolName" placeholder="请选择我的学校">
            <el-option v-for="(item, index) in schoolList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-select
            style="display:block"
            v-model="userFormData.schoolName"
            filterable
            remote
            :remote-method="remoteSearch"
            placeholder="请输入关键词"
            >
            <el-option
              v-for="item in collegeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
         <!-- <el-input v-model="userFormData.schoolName" placeholder="请输入我的学校"></el-input> -->
        </el-form-item>
      </el-form>
      <el-button class="login-text" type="primary" round @click="toSet('userFormData')">确认</el-button>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2';
import { getWxUrl } from 'api/verification.js';
import { uploadFile } from 'api/index.js';
import { sendSms, login, cmsUserUpdate } from 'api/login.js';
import { getBkSchool } from 'api/caseSharing.js';
export default {
  name: 'VerificationDialog',
  data() {
    const mobileRule = (rule, value, callback) => {
      const regExp = /^1[3456789]\d{9}$/;
      if (regExp.test(value) === false) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        verifyCode: [{ message: '请输入验证码', required: true, trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, message: '手机号码长度有误', trigger: 'blur' },
          { validator: mobileRule, trigger: ['blur', 'change'] }
        ]
      },
      schoolList: [

      ],
      userFlag: false,
      checked: false,
      verificationFlag: true,
      activeIndex: 1,
      formInfo: {},
      userFormData: {},
      codeText: '获取验证码',
      disabledFlag: false,
      countdown: 60, // 倒计时初始值
      timerId: null,
      smsCode: "",
      collegeList: []
    };
  },
  mounted() {
    // this.onLinkWechat();
  },
  methods: {
    onLinkWechat() {
      // linkWechat().then(res => {
      //   if (res.code == 2000) {
      getWxUrl().then(res => {
        if (res.code == 2000) {
          this.createQRCode(res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      //   } else {
      //     this.$message({
      //       type: 'error',
      //       message: res.msg
      //     });
      //   }
      // });
    },
    createQRCode(url) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';
        // eslint-disable-next-line no-unused-vars
        const code = new QRCode(this.$refs.qrCodeDiv, {
          text: url,
          width: 174,
          height: 174,
          colorDark: '#333333',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L
        });
      });
    },
    toSet(formName) {
      if (this.userFormData.name == null) {
        this.$message({
          type: 'error',
          message: '请输入用户昵称'
        });
        return;
      }
      if (this.userFormData.schoolName == null) {
        this.$message({
          type: 'error',
          message: '请输入学校名称'
        });
        return;
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          cmsUserUpdate(this.userFormData).then(res => {
            if (res.code == 2000) {
              this.$message({
                type: 'success',
                message: '登录成功'
              });
              localStorage.setItem('userInfo', this.userFormData);
              this.closeClick();
              window.location.reload();
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getCode() {
      if (this.timerId) {
        clearInterval(this.timerId); // 清除之前的计时器，防止重复发送验证码
      }
      this.disabledFlag = true;
      this.countdown = 60;
      const data = {
        mobile: this.formInfo.mobile,
        type: 1
      };
      sendSms(data).then(res => {
        if (res.code === '2000') {
          this.$message({
            type: 'success',
            message: '验证码发送成功！'
          });
          this.smsCode = res.data;
          this.startCountdown(); // 发送成功后开始倒计时
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.disabledFlag = false; // 发生错误时，重新启用获取验证码按钮
        }
      });
    },
    startCountdown() {
      this.timerId = setInterval(() => {
        this.countdown--;
        this.codeText = `${this.countdown}s后`;
        if (this.countdown <= 0) {
          this.codeText = '获取验证码';
          this.disabledFlag = false;
          clearInterval(this.timerId);
          this.timerId = null; // 清除计时器ID，以便下次可以重新设置
        }
      }, 1000);
    },
    toLogin(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.smsCode == this.formInfo.verifyCode) {
            this.verificationFlag = false;
            login(this.formInfo).then(res => {
              if (res.code == 2000) {
                this.$message({
                  type: 'success',
                  message: res.msg
                });
                this.userFormData = res.data;
                if (res.data.schoolName != null && res.data.name !== null) {
                  localStorage.setItem('token', res.data.cmsToken);
                  localStorage.setItem('userInfo', res.data);
                  window.location.reload();
                } else {
                  this.userFlag = true;
                  localStorage.setItem('token', res.data.cmsToken);
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                });
             }
            });
          } else {
            this.$message.error('验证码错误！');
            this.disabledFlag = false;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closeClick() {
      this.$store.dispatch('callSetIshow', false);
    },
    handleAvatarSuccess(res, file) {
      this.userFormData.headLogo = URL.createObjectURL(file.raw);
    },
    handleRemove(file, fileList) {
      this.$set(this.userFormData, 'imageUrl', '');
    },
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      console.log(isJPG, isPNG);
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.$set(this.userFormData, 'headLogo', res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.collegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    }
  }
};
</script>
<style  lang='stylus' scoped>
.tabBox {
  font-size: 20px;
  color: #A0A7B7;

  .active {
    color: #FF8240;
    font-weight: bold;
  }
}

.codeBox {
  #qrCode {
    width: 174px;
    height: 174px;
    margin-top: 30px;
  }

  p {
    padding: 20px 0;
    font-size: 16px;
  }
}

.mobileBox {
  margin: 40px 58px 5px;

  :deep .el-input__inner {
    border: none;
  }

  img {
    width: 22px;
    height: 22px;
  }

  :deep .el-form-item__content {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CAD1E2;
  }

  :deep .el-form-item__content {
    margin-left: 0 !important;
  }
}

.login-text {
  width: 100%;
  height: 60px;
  background: #FF8240;
  border-radius: 30px !important;
  color: #fff;
  border-color:#FF8240 !important;
  margin-top: 10px;
  font-size: 18px;
}

:deep .el-form>div:last-child .el-form-item__content {
  border-bottom: none;
}

.avatar-uploader, .el-upload {
  width: 80px;
  height: 80px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #FF8240;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.avatar-uploader-text {
  color: #fff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #CCCCCC;
  border-radius: 50%;
  font-size: 12px;
}
.el-button--text{
  color: #FF8240 !important;
}
.el-button--primary:focus, .el-button--primary:hover{
  background: #FF8240 !important;
  border-color:#FF8240 !important;

}
>>>.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #333333 !important;
}
>>>.el-checkbox__input.is-checked .el-checkbox__inner{
  background: #FF8240 !important;
  border-color:#FF8240 !important;
}
>>>.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #FF8240 !important;
}
</style>