<template>
  <div class="collBox">
    <leftNav></leftNav>
    <div class="collContent">
      <div class="contetn_tab">
          <p class="toggle" :class="{'active':index==activeIndexes}" @click="toggleChange(index)" v-for="(item,index) in toggleList" :key="index">
              <span>{{ item.name }}</span>
          </p>
      </div>
      <div class="schoolBox">
        <div class="schoolList" v-show="activeIndexes==0">
          <div v-if="schoolList.length>0">
          <div class="schoolItem" v-for="(item,index) in schoolList" :key="index" @click="toProfessionalList(item)">
            <img :src="item.logo" alt="" />
            <div class="info">
              <p>{{ item.universityNameCn }}</p>
              <span>{{ item.universityNameEn || '暂无'}}</span>
              <span>{{ item.area }}</span>
              <p>
                <span>专业数：<font>{{ item.countCollege }}</font></span>
                <span>排名：<font>{{ item.qsRank }}名</font></span>
              </p>
            </div>
            <div class="collect" @click.stop="cancle(item,0)">
              收藏
              <i class="el-icon-star-on"></i>
            </div>
          </div>
        </div>
          <el-empty v-else-if="schoolList.length==0" :image="require('../../assets/noData.png')"></el-empty>
        </div>
        <div class="majorBox" v-show="activeIndexes==1">
          <div v-if="majorList.length>0">
            <div class="majorItem" v-for="(item,index) in majorList" :key="index" @click="toDetail(item)">
              <img :src="item.logo" alt="" />
              <div class="info">
               <p>{{ item.universityNameCn }}|{{ item.programNameCn }}</p>
                <p>{{ item.programNameEn || '暂无' }}</p>
                <p>
                  <!-- <span>{{getMonthFromTimeStamp(item.timeOff)}}月入学已截止</span> -->
                  <span v-for="(items,indexs) in item.majors" :key="indexs">{{ items.majorRankStr }}</span>
                </p>
                <span>{{ item.area }}</span>
                <div class="collect" @click.stop="cancle(item,1)">
                  收藏
                  <i class="el-icon-star-on"></i>
                </div>
              </div>
            </div>
          </div>
          <el-empty v-else-if="majorList.length==0"  :image="require('../../assets/noData.png')"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftNav from "./leftNav";
import { mySchoolCollection, myMajorCollection } from 'api/my.js';
import { doCollection } from 'api/libraryList.js';
export default {
  components: {
    leftNav,
  },
  data() {
    return {
      toggleList: [
          {
              name: '我的学校',
              value: '0',
          },
          {
              name: '我的项目',
              value: '1',
          },
      ],
      activeIndexes: 0,
      schoolList: [],
      majorList: []
    };
  },
  methods: {
    toggleChange(index) {
            this.activeIndexes = index === this.activeIndexes ? [] : [index];
            if (this.activeIndexes == 0) {
                this.getSchoolCollection();
            } else {
                this.getMajorCollection();
            }
        },
    getSchoolCollection() {
      const data = {};
      mySchoolCollection(data).then(res => {
            if (res.code == 2000) {
              this.schoolList = res.data || [];
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
    },
    getMajorCollection() {
      const data = {
        userId: localStorage.getItem('userInfo').id,
      };
      myMajorCollection(data).then(res => {
            if (res.code == 2000) {
              this.majorList = res.data || [];
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
    },
    getMonthFromTimeStamp(ts) {
      const date = new Date(ts);
      const month = 1 + date.getMonth(); // 月份从0开始，加1得到实际月份
      return month;
    },
    cancle(val, type) {
      const params = {
        collectionId: val.id,
        collectionType: type,
        isDelete: 1
      };
      doCollection(params).then(res => {
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: '取消收藏成功'
          });
          this.getMajorCollection();
          this.getSchoolCollection();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },

    toProfessionalList(val) {
      this.$router.push({
        path: '/professionalList',
        query: {
          schoolId: val.id
        }
      });
    },
   toDetail(val) {
    this.$router.push({
      path: `/professionalDetails?id=${val.id}&schoolId=${val.universityId}`
      });
    },

  },
  mounted() {
    this.getSchoolCollection();
  },
  created() {},
  watch: {},
};
</script>

<style lang='stylus' scoped>
@import '~css/stylus.styl';
.collBox{
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color:#F5F7FA;
  padding-bottom: 60px;
  margin: 0 auto;
}
.collContent{
  width 940px;
  background-color: #fff;
  margin: 27px 2% 27px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
  padding: 23px 20px;
}
.contetn_tab{
  display: flex;
  justify-content:center;
  margin-bottom: 30px;
  .toggle{
      img{
          width: 15px;
          margin-right: 5px;
      }
      img:nth-of-type(2){
         display: none
      }
  }
  p{
    font-size: 16px;
    color: #666666;
    cursor: pointer;
    width 190px;
    height 40px;
    line-height 40px;
    border: 1px solid #EEEEEE;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}
  p:nth-of-type(1){
    border-radius: 20px 0 0 20px;
    border-right: none;
  }
  p:nth-of-type(1).active{
      background: linear-gradient(-71deg, transparent 13px, #FFEFE6 0) top right;
      color: $themeColor;
  }
  p:nth-of-type(2){
      border-radius: 0 20px 20px 0;
      border-left: none;
      margin-left: -16px;
  }
  p:nth-of-type(2).active{
      background: linear-gradient(-70deg,#FFEFE6 177px, transparent  0) left top;
      color: $themeColor;
  }
}
.schoolBox{
  margin: 30px auto;
}
.schoolList{
  .schoolItem{
    display: flex;
    width: calc((100% - 20px)/2);
    margin-bottom: 20px;
    padding: 18px 15px;
    background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
    border-radius: 14px;
    box-sizing: border-box;
    float: left;
    transition: all 0.5s;
    position:relative;
    cursor: pointer;
    img{
      width:50px;
      display: block;
      margin-right: 15px;
      height 50px;
    }
    .info{
      p:nth-of-type(1){
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-bottom: 11px;
      }
      >span{
        font-size: 14px;
        color: #333333;
        display:block;
        margin-bottom: 11px;
        height: 18px;
      }
      p:last-of-type{
        span{
          font-size: 14px;
          color: #333333;
          display: inline-block;
          margin-right:40px;
          font{
            font-size: 14px;
            color: #FF772E;
            font-weight: bold;
          }
        }
      }
    }
  }
  .schoolItem:hover{
    box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
    .info>p:nth-of-type(1){
        color: $themeColor;
      }
  }
  .schoolItem:nth-of-type(2n){
    float: right;
  }
}
.collect{
  position: absolute;
  top: 0;
  right: 0;
  width: 79px;
  height:28px;
  text-align: center;
  line-height: 28px;
  border-radius: 0 14px 0 14px
  background-color: #F9EEE5;
  font-size: 14px;
  color:#FF8240;
  cursor:pointer;
  i{
   font-size: 20px;
   vertical-align: -2px;
  }
}
.schoolBox::after{
  content: "";
  display: block;
  clear: both;
}
.majorBox{
  .majorItem{
    display: flex;
    width:100%;
    margin-bottom: 20px;
    padding: 15px;
    box-sizing: border-box;
    background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
    border-radius:  14px;
    transition: all 0.5s;
    position:relative;
    cursor: pointer;
    img{
      width 50px;
      height 50px;
      margin-right: 15px;
    }
    .info{
      width:calc(100% - 65px);
      p:nth-of-type(1){
        font-weight: bold;
        font-size: 18px;
        color: $themeColor;
        margin-bottom: 11px;
      }
      p:nth-of-type(2){
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #333333;
        margin-bottom: 11px;
        span{
          font-size: 14px;
          color: #333333;
          font{
            font-weight: bold;
            font-size: 14px;
            color: #FF772E;
          }
        }
      }
      p:nth-of-type(3){
        margin-bottom: 8px;
        span{
          padding: 6px 9px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #CCCCCC;
          display: inline-block;
          margin-right: 10px;
          font-size: 12px;
        }
        span:nth-of-type(1){
            color: #FF772E;
            border-color: #FF772E;
        }
      }
      >span{
        font-size: 14px;
        color: #333333;
        display:block;
        margin-bottom: 11px;
        display: block;
      }
    }
  }
  .majorItem:hover{
    box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
    .info>p:nth-of-type(1){
        color: $themeColor;
      }
  }

}
.el-empty{
  padding:80px 0
}
</style>
<style>
#app{
    background-color: #F5F7FA;
}
</style>