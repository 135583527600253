<template>
  <div class="content-container">
    <el-main>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread-bg conTop" :class="{transparent:translate}">
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        <el-breadcrumb-item v-show="titles != undefined" v-for="(item, index) in titles" :key="index">{{ item }}</el-breadcrumb-item>
        <div>
          <el-button type="text" size="mini" v-show="showBtn" @click="cancelList">返回上一页</el-button>
        </div>
      </el-breadcrumb>
    </el-main>
  </div>
</template>

<script>
export default {
  props: ['title', 'titles', 'showBtn', 'route', 'id', 'translate', 'isBack'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 返回列表路由
    cancelList() {
      if (this.isBack) {
        this.$emit('back');
       } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';
/deep/ .el-main{
  display: flex
  justify-content: center
}
.content-container .conTop {
  overflow: hidden;
  width: $width;
  height: 39px;
  text-align: left;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-sizing: border-box;
  margin: 20px 0;
  border-radius: 10px 10px 10px 10px;
  font-size: 12px
}
/deep/ .el-button--text{
  color: #333
}
.content-container .conTop>img {
  margin-right: 6px;
}

.bread-bg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: rgba(232, 247, 255, 1);
  /* background:url(../../assets/ifram-head.png) repeat-x; */
  background-size: 100% 100%;
}

.bread-bg i {
  margin: 0 6px;
}

.bread-bg div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}
.transparent{
  background: rgba(255, 255, 255, 0.2) !important;
  >>>.el-breadcrumb__inner{
    color:#fff !important;
  }
  .el-button{
    color:#fff !important;
  }
  >>>.el-breadcrumb__separator{
    color:#fff !important;
  }
}
>>>.el-breadcrumb .el-breadcrumb__item:last-of-type .el-breadcrumb__separator{
  display: none !important;
}
</style>
