<template>
  <div class="">
    <el-dialog title="意向专业方向" :close-on-click-modal="false" :visible.sync="dialogVisible" width="900px" :before-close="openDialog">
      <!-- <div class="flex tabBox">
        <p @click="changeTab(1)" :class="activeIndex == 1 ? 'leftActive' : 'left'">任何本科背景都可以申请的专业</p>
        <p @click="changeTab(2)" :class="activeIndex == 2 ? 'rightActive' : 'right'">该类专业适用于任何理工科背景</p>
      </div> -->
      <!-- 专业类别 -->
      <div class="flexBetweenCenter choose-box">
        <div class="flex-center">
          <span class="choose-box-title">专业类别</span>
          <div class="flex-center">
            已选择：
            <el-button v-if="chooseListData.length == 0" size="mini">尚未选择</el-button>
            <el-tag color="#FFEFE6" size="small" v-for="(item, index) in chooseListData" :key="index" closable :disable-transitions="false" @close="handleClose(index)">
              {{ item.name }}
            </el-tag>

          </div>
        </div>
        <div class="flex-center">
          {{ chooseListData.length }}/5
          <p class="dialog-btn finger" @click="toSearch">确定</p>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item :name="item.id" v-for="(item, indexs) in majorData" :key="indexs">
          <template #title>
            <span class="collapse-title">{{ item.name }}</span>
          </template>
          <div class="major-box flex-wrap">
            <el-checkbox-group :max="5" @change="handleCheckedChange" v-model="chooseList" size="small">
              <el-checkbox-button @change="btnChange(child,item,indexs)" v-for="(child, index) in item.childDict" :key="index" :label="child.id" border>
                {{ child.name }}
              </el-checkbox-button>
            </el-checkbox-group>
            <!-- <el-button style="margin-bottom: 12px;" size="mini" :class="[item.isSelected?'acts':'act']" v-model="item.isSelected" @click="btnAll(item,indexs)">全选以上专业</el-button> -->
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { getDictTreeByCode } from 'api/index.js';
export default {
  name: '',
  components: {},
  data() {
    return {
      activeNames: [],
      chooseList: [],
      dialogVisible: false,
      checkboxGroup1: [],
      activeIndex: 1,
      majorData: [],
      chooseListData: [],
      showList: [],
      sendDataList: [],
      sendShowList: []
    };
  },
  methods: {
    toSearch() {
      console.log(this.chooseListData, 'this.chooseListData');
      this.sendShowList = [];
        this.sendDataList = [];
      if (this.chooseListData.length == 0) {
        this.$emit('sendShowList', []);
        this.$emit('sendDataList', []);
        this.chooseListData = [];
      } else {
        this.chooseListData.map(item => {
          this.sendShowList.push(item.name);
          this.sendDataList.push(item.id);
        });
        this.$emit('sendShowList', this.sendShowList);
        this.$emit('sendDataList', this.sendDataList);
      }
      console.log(this.sendShowList, 'this.showList');

      this.dialogVisible = false;
    },
    changeTab(val) {
      this.activeIndex = val;
    },

    btnAll(parent, index) {
      if (this.chooseListData.length >= 5) {
            this.showError('最多选择5个专业');
            return; // 立即返回，防止后续逻辑执行
          }
      parent.isSelected = !parent.isSelected;
      if (this.chooseList.length > 0) {
        this.showError('专业大类与专业小类不能共存');
        this.majorData[index].isSelected = !parent.isSelected;
      } else if (parent.isSelected) {
        this.chooseListData = this.chooseListData.concat(parent);
      } else {
        this.chooseListData = this.chooseListData.filter(listItem => listItem.id !== parent.id);
      }
     },
     btnChange(child, parent, parentIndex) {
      if (this.chooseListData.includes(child)) {
          // 如果存在，则移除 child
          this.chooseListData = this.chooseListData.filter(item => item !== child);
          this.chooseList = this.chooseList.filter(item => item !== child);
         console.log(this.chooseList, 'this.chooseListsss');
        } else {
          // 如果不存在，则添加 child
          this.chooseListData = this.chooseListData.concat(child);
        };
      // if (this.chooseListData.length >= 5) {
      //   this.showError('最多选择5个专业');
      //   return; // 立即返回，防止后续逻辑执行
      // }
      // if (this.majorData.some(item => item.isSelected)) {
      //   this.showError('专业大类与专业小类不能共存');
      //   this.chooseList = [];
      // } else {
      //   if (this.chooseListData.includes(child)) {
      //     // 如果存在，则移除 child
      //     this.chooseListData = this.chooseListData.filter(item => item !== child);
      //     this.chooseList = this.chooseList.filter(item => item !== child);
      //    console.log(this.chooseList, 'this.chooseListsss');
      //   } else {
      //     // 如果不存在，则添加 child
      //     this.chooseListData = this.chooseListData.concat(child);
      //   };
      //   // 去掉不必要的 console.log
      // }
    },
    showError(message) {
      this.$message({
        type: 'error',
        message: message
      });
    },
    handleCheckedChange() {
      // 如果chooseList里面有这个专业就删除，如果没有就添加
      console.log(this.chooseList, 'this.chooseList');
      this.majorData = this.majorData.map(item => {
        if (this.chooseList.includes(item)) {
          return { ...item, isSelected: true };
        }
        return item;
      });
    },
    handleClose(index) {
      // 处理关闭 tag 的逻辑
      this.chooseList.splice(index, 1);
      this.chooseListData.splice(index, 1);
      this.majorData = this.majorData.map(item => {
        if (item.id === this.majorData[index].id) {
          return { ...item, isSelected: false };
        }
        return item;
      });
    },
    // handleCheckedChange(value) {
    //   if (this.chooseList.length > 5) {
    //     this.$message.error('最多选择5个专业');
    //   }
    //   // const checkedCount = value.length;
    //   // this.checkAll = checkedCount === this.cities.length;
    //   // this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    // },
    // handleClose(index) {
    //   this.chooseList.splice(index, 1);
    // },
    openDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    getDictTreeByCode() {
      const data = {
         code: 'YXZYS'
      };
      getDictTreeByCode(data).then(res => {
        if (res.code == '2000') {
         this.majorData = res.data;
         console.log(this.majorData);
        //  this.majorData.forEach((item) => {
        //     item.childDict.push({ name: '全选以上专业', id: item.id });
        //     item.isSelected = false;
        //   });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
  },
  mounted() {
    this.getDictTreeByCode();
  },
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';
:deep .el-collapse-item__header {
  background-color: #F8F8F8 !important;
  padding-left: 10px;
}

:deep .el-collapse {
  border-radius: 3px;
  border: 1px solid #CCCCCC !important;
}

:deep .el-collapse-item__header, :deep .el-collapse-item__wrap {
  border-bottom: 1px solid #CCCCCC !important;
}

:deep .el-collapse-item__content {
  padding-left: 20px;
  padding-bottom: 0 !important;
}

:deep .collapse-title {
  flex: 1 0 90%;
  order: 1;

  .el-collapse-item__header {
    flex: 1 0 auto;
    order: -1;
  }
}

:deep .el-dialog__body {
  padding-top: 5px;
}

:deep .el-dialog__header {
  font-weight: bold;
  font-size: 18px;
  color: #666666;
}

::deep .el-collapse-item__content {
  padding-bottom: 0;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.choose-box {
  margin: 21px 0;

  .choose-box-title {
    font-weight: bold;
    font-size: 14px;
    color: #666666;
    margin-right: 20px;
  }

  .dialog-btn {
    width: 58px;
    line-height: 30px;
    background: $themeColor;
    border-radius: 15px 15px 15px 15px;
    color: #fff;
    text-align: center;
    margin-left: 9px;
  }
}

.major-box {
  margin-top: 12px;
}

/deep/ .is-checked .el-checkbox-button__inner, /deep/ .is-focus .el-checkbox-button__inner, /deep/ .el-checkbox-button__inner, /deep/ .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 1px solid #CCCCCC;
  border-radius: 4px !important;
  margin-right: 10px;
  margin-bottom: 12px;
  color: #333333;
}

/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #FFEFE6;
  border: 1px solid $themeColor;
  color: $themeColor;
  box-shadow: 0 0 0 0;
}

.tabBox {
  p {
    width: 440px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #CCCCCC;
  }

  p:first-child {
    margin-right: -10px;
    border-right: none;
  }

  p:last-child {
    margin-left: -10px;
  }

  .left {
    border-radius: 22px 0 0 22px
    color: #666666;
    border: 1px solid #CCCCCC;
  }

  .leftActive {
    background:linear-gradient(108deg, $themeColor 418px, #fff 0) top left;
    border-radius: 22px 0 0 22px
    color: #fff;

  }

  .right {
    border-radius: 0 20px 20px 0;
    color: #666666;
    border: 1px solid #CCCCCC;
    border-left: none;
  }

  .rightActive {
    border-radius: 0 20px 20px 0;
    background: linear-gradient( -68deg ,$themeColor 402px, #fff 0) top right;
    border-left: none;
    color: #fff;
  }

}
.acts{
  background: #ffefe6;
    border: 1px solid #ff8240;
    color: #ff8240;
    box-shadow: 0 0 0 0;
}

</style>
