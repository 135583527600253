<template>
  <div class="professionalList">
    <headerTitle title="首页" :titles="['院校匹配', '项目列表']" :showBtn="true" :translate="true" :isBack='isBack' @back="back"></headerTitle>
    <div class="flex-center-column">
      <!--  -->
      <boxHead :schoolId="schoolId" :isContrast="isContrast"></boxHead>
      <!--  -->
      <div class="professional-list" v-if="iscollage">
        <div class="professional-box-title">
          <p>
            <span></span>
            学院
          </p>
          <span @click="changeExhibit" class="finger flex-center">
            {{ changeTxt }}
            <img :src="changeIcon" alt="" />
          </span>
        </div>
        <!-- <div class="tabBox flex-wrap">
          <p v-for="(item, index) in tablist" :key="index" :class="{ 'tab-active': item == searchTab }" @click="toSearch(item, index)">{{ item.name }}</p>
        </div> -->
        <div class="majorBox flex-wrap" v-show="changeTxt === '图文'">
          <div v-for="(item, index) in tablist" :key="index" @click="handleRowClick(item)">
            <p class="major-name">{{ item.collegeNameCn }}</p>
            <p class="major-names">{{ item.collegeNameEn }}</p>
            <div class="rankbox">
              <p>
                <span class="ranking">QS排名：<font>{{ item.qsRank }}名</font></span>
                <span class="ranking">U.S.news排名：<font>{{ item.qsRank }}名</font></span>
              </p>
              <p>
                <span class="ranking">The排名：<font>{{ item.qsRank }}名</font></span>
                <span class="ranking">Rank排名：<font>{{ item.qsRank }}名</font></span>
              </p>
            </div>
          </div>
        </div>
        <div v-show="changeTxt === '列表'">
          <el-table :data="tablist" @row-click="handleRowClick">
            <el-table-column type="index" label="序号" align="center">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column label="中文学院名称" prop="collegeNameCn" align="center"></el-table-column>
            <el-table-column label="英文学院名称" prop="collegeNameEn" align="center"></el-table-column>
            <el-table-column label="QS排名" prop="qsRank" align="center"></el-table-column>
            <el-table-column label="U.S.news排名" prop="usnewsRank" align="center"></el-table-column>
            <el-table-column label="THE排名" prop="theRank" align="center"></el-table-column>
            <el-table-column label="Rank排名" prop="ruankeRank" align="center"></el-table-column>
          </el-table>
        </div>
        <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick" style="margin-top: 20px"></paging>
      </div>
      <div class="professional-list" v-if="isProject">
        <div class="professional-box-title">
          <p>
            <span></span>
            项目
            <a class="links" @click="toColleag">{{ collageNameCn }}&nbsp;&nbsp;&nbsp;{{ collageNameCn }}</a>
          </p>
          <span @click="changeExhibit" class="finger flex-center">
            {{ changeTxt }}
            <img :src="changeIcon" alt="" />
          </span>
        </div>
        <!-- <div class="tabBox flex-wrap">
          <p v-for="(item, index) in tablist" :key="index" :class="{ 'tab-active': item == searchTab }" @click="toSearch(item, index)">{{ item.name }}</p>
        </div> -->
        <div class="majorBox flex-wrap" v-show="changeTxt === '图文'">
          <div v-for="(item, index) in majorList" :key="index" @click="handleRowClicks(item)">
            <p class="major-name">{{ item.programNameCn }}</p>
            <p class="major-names">{{ item.programNameEn }}</p>
            <p class="pro"><span v-for="(items,indexs) in item.majors" :key="indexs">{{ items.majorRankStr }}</span></p>
          </div>
        </div>
        <div v-show="changeTxt === '列表'">
          <el-table :data="majorList" @row-click="handleRowClicks">
            <el-table-column type="index" label="序号" align="center">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column label="中文项目名称" prop="programNameCn" align="center"></el-table-column>
            <el-table-column label="英文项目名称" prop="programNameEn" align="center"></el-table-column>
            <el-table-column label="专业排名" prop="Majorinner" align="center">
              <!-- <template slot-scope="scope">

                <span v-for="(items,indexs) in scope.row.majors" :key="indexs">{{ items.majorRankStr }}</span>
              </template> -->
            </el-table-column>
          </el-table>
        </div>
        <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick" style="margin-top: 20px"></paging>
      </div>
      <div class="professional-list" v-if="isDetail">
        <div class="professional-box-title">
          <p>
            <span></span>
            学院详情
          </p>
        </div>
        <div class="collegaBox">
            <p>
              <span @click="toHome">学院名称（中文）：{{ collageInfo.collegeNameCn }}</span>
              <span @click="toHome">学院名称（英文）：{{ collageInfo.collegeNameEn }}</span>
              <span>QS排名：{{ collageInfo.qsRank }}</span>
              <span>U.S.News排名：{{ collageInfo.usnewsRank }}</span>
              <span>The排名：{{ collageInfo.theRank }}</span>
              <span>Rank排名：{{ collageInfo.ruankeRank }}</span>
            </p>
            <p>简介（中文）:{{collageInfo.introduction}}</p>
            <p>简介（英文）：{{ collageInfo.introductionEn }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import paging from '@/components/paging.vue';
import boxHead from './components/boxHead.vue';
import { getAcademyList, getcmsCollegedetail } from 'api/collegeMatching.js';
import { getMajorRankList } from 'api/libraryList.js';
import { mapActions } from 'vuex';
export default {
  name: 'ProfessionalList',
  components: { headerTitle, paging, boxHead },
  data() {
    return {
      schoolId: '',
      pageall: 0,
      pagenum: 1,
      tablist: [],
      searchTab: '',
      majorList: [],
      changeTxt: '列表',
      iscollage: true,
      collageInfo: {},
      collageId: '',
      collageNameCn: "",
      collageNameEn: "",
      isDetail: false,
      isProject: false,
      isBack: false,
      isContrast: false
    };
  },
  mounted() {
    this.schoolId = this.$route.query.schoolId;
    this.getList();
  },
  methods: {
    ...mapActions(['trackEvent']),
    getList() {
      const data = {
        universityId: this.schoolId
      };
      getAcademyList(data).then(res => {
        if (res.code == '2000') {
          this.tablist = res.data.list || [];
          // this.searchTab = res.data.list[0] || [];
          // if (res.data.list.length > 0) {
          //   this.getMajorList();
          // }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getMajorList(id) {
      const data = {
        universityId: this.schoolId,
        collegeId: id,
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
      };
      getMajorRankList(data).then(res => {
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.majorList = res.data.list || [];
          this.majorList.map(item => {
            item.Majorinner = item.majors.map(items => items.majorRankStr).join('、');
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    handleRowClick(row, event, column) {
      this.toDetail(row);
      this.iscollage = false;
      this.isProject = true;
      this.isBack = true;
    },
    handleRowClicks(row, event, column) {
       this.$router.push({
        path: `/professionalDetails?id=${row.id}&schoolId=${this.schoolId}`
      });
      this.trackEvent({ action: '项目详情页' });
    },
    toDetail(val) {
      // this.$router.push({
      //   path: `/professionalDetails?id=${val.id}&schoolId=${this.schoolId}`
      // });
      this.getMajorList(val.id);
      this.collageId = val.id;
      this.collageNameCn = val.collegeNameCn;
      this.collageNameEn = val.collegeNameEn;
      this.isProject = !this.isProject;
    },
    changeExhibit() {
      this.changeTxt = this.changeTxt === '列表' ? '图文' : '列表';
    },
    pageclick(num) {
      this.pagenum = num;
    },
    toSearch(val) {
      this.searchTab = val;
    },
    toColleag() {
      this.isDetail = !this.isDetail;
      this.isProject = !this.isProject;
      const data = {
        id: this.collageId,
      };
      getcmsCollegedetail(data).then(res => {
        if (res.code == '2000') {
          this.collageInfo = res.data || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      this.trackEvent({ action: '学院详情页' });
     },
     toHome() {
       window.open(this.collageInfo.homepage, '_blank');
     },
     back() {
      if (this.iscollage) {
          this.isBack = false;
          this.$router.go(-1);
        } else {
          if (this.isProject) {
            this.iscollage = true;
            this.isProject = false;
          } else if (this.isDetail) {
            this.isProject = true;
            this.isDetail = false;
          }
        }
    }
  },
  computed: {
    changeIcon() {
      return this.changeTxt === '列表' ? require('@/assets/list.png') : require('@/assets/picture.png');
    }
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.professionalList {
  width: 100%;
  background: #F5F7FA url('../../assets/professionalList.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .professional-list {
    box-sizing: border-box;
    width: $width;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 20px;
    padding: 0 20px 45px;
  }

  .professional-list {
    .professional-box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      position:relative;
      .links{
        font-weight: bold;
        font-size: 20px;
        color: $themeColor;
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        width: 18px;
        display: inline-block;
        margin-left: 10px;
      }

      p {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        line-height: 32px;
        display: flex;
        align-items: center;
        &>span {
          line-height: 32px;
          display: inline-block;
          color: $themeColor;
          width: 4px;
          height: 20px;
          background: $themeColor;
          border-radius: 90px 90px 90px 90px;
          margin-right: 8px;
        }
      }
    }
  }

  .tabBox {
    background: #FAFBFC;
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 30px;
    padding: 20px 20px 0;

    p {
      padding: 0 23px;
      text-align: center;
      line-height: 30px;
      background: rgba(207, 214, 226, 0);
      border-radius: 15px 15px 15px 15px;
      border: 1px solid #CCCCCC;
      margin: 0 20px 15px 0;
      cursor: pointer;
    }

    .tab-active {
      background: $themeColor;
      color: #fff;
    }
  }

  .majorBox {
    &>div {
      width: 244px;
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      border-radius: 14px 14px 14px 14px;
      margin: 0 21px 20px 0;
      padding: 15px;
      cursor: pointer;
      transition: all 0.3s;
    }

    &>div:hover {
      box-shadow: 0px 5px 30px 1px rgba(0, 0, 0, 0.1);

      .major-name {
        color: $themeColor;
      }
    }
    &>div:nth-of-type(4n){
      margin-right: 0;
    }
    .major-name {
      font-weight: bold;
      font-size: 18px;
    }

    >span {
      font-weight: bold;
      color: #FF772E;
    }
    .major-names{
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      margin: 8px 0 9px;
      line-height: 22px;
    }
    .ranking {
      padding: 8px 0;
      font-size: 14px;
      font{
        font-weight: bold;
        color: #FF772E;
      }
    }

    .major-date {
      line-height: 22px;
    }
    .rankbox{
      p{
        display: flex;
        justify-content: space-between;

      }
    }
    .pro{
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      span{
        float: left;
        line-height: 26px;
      }
      span:nth-of-type(2n){
        float: right;
      }
    }
    .pro:after{
      content: '';
      display: block;
      clear: both;
    }

  }
}
>>>.el-table__body tr .el-table__cell{
  padding:15px 0 !important;
}
.collegaBox{
  p{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  p:nth-of-type(1){
    span:nth-of-type(1){
      font-weight: 400;
      font-size: 14px;
      color: #5574D8;
      text-decoration-line: underline;
      cursor: pointer;
    }
    span:nth-of-type(2){
      font-weight: 400;
      font-size: 14px;
      color: #5574D8;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
</style>