<template>
  <div class="caseshareBox">
    <div class="topban">
      <div class="topban_left">
        <p>
          <img src="~@/assets/share/iconleft.png" alt="" />案例分享<img
            src="~@/assets/share/iconright.png"
            alt=""
          />
        </p>
        <span>在梦想飞翔的道路上，为你助力</span>
      </div>
    </div>
    <div class="formbox">
      <el-form :model="form" ref="forms"  label-width="120px">
        <el-form-item label="留学意向地区：" prop="areaId">
          <el-select v-model="form.areaId" placeholder="请选择" clearable>
            <el-option
              v-for="item in ZXDQList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业方向：" prop="majorNameList">
          <el-select v-model="form.majorNameList" placeholder="请选择" clearable @change="changepro">
            <el-option
              v-for="item in ZYFXList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="子类方向：" prop="sonClass">
          <el-select v-model="form.sonClass" placeholder="请选择" clearable >
            <el-option
              v-for="item in professionalsonList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="院校组合：" prop="schoolGroup">
          <el-select v-model="form.schoolGroup" placeholder="请选择" >
            <el-option
              v-for="item in combinationList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QS：" prop="qs">
          <el-select v-model="form.qs" placeholder="请选择"  @change="changeQs" clearable>
            <el-option
              v-for="item in qsList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自定义QS：" class="inputClass">
          <el-input v-model="form.startQsRank"  type="number" @input="form.qs=''" placeholder="请输入"></el-input>
          <span>一</span>
          <el-input v-model="form.endQsRank" type="number" @input="form.qs=''" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="院校等级：" prop="levelId">
          <el-select v-model="form.levelId" placeholder="请选择" @change="changeLevel">
            <el-option
              v-for="item in LEVELList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本科大学名称：" prop="bkSchoolName">
          <el-select
          clearable
            v-model="form.bkSchoolName"
           filterable
           remote
          :remote-method="remoteSearch"
          :loading="loading"
            placeholder="请输入关键词"
            @change="bkSchoolNameChange"
            >
            <el-option
              v-for="item in collegeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
          <el-checkbox v-model="form.checked">只根据本科大学名称筛选</el-checkbox>
          <p class="tips">(说明:若填写了“本科大学名称”并勾选此项,则最终结果只返回该学校案例;若未勾选此项,则最终结果返回该学校同等级别院校案例。)</p>
        </el-form-item>
        <el-form-item label="年份:" label-width="120px" prop="year">
            <el-date-picker clearable v-model="form.year"  format="yyyy" value-format="yyyy"  type="year" placeholder="请选择">
            </el-date-picker>
          </el-form-item>
        <el-form-item label="结果：" prop="caseType">
          <el-select v-model="form.caseType" placeholder="请选择" filterable >
            <el-option
              v-for="item in resultsList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="btnList">
          <el-button round @click="resetForm('forms')">重置</el-button>
          <el-button round type="primary" @click="onSubmit">查询</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { dicListByCode } from 'api/index.js';

import { getCaseInfoList, getBkSchool } from 'api/caseSharing.js';

export default {
  components: {},
  data() {
    return {
      form: {
        areaId: '',
        majorNameList: '',
        sonClass: '',
        schoolGroup: null,
        qs: '',
        startQsRank: '',
        endQsRank: '',
        levelId: null,
        bkSchoolName: '',
        year: '',
        caseType: '',
        checked: false,
      },
      ZXDQList: [],
      ZYFXList: [],
      professionalsonList: [],
      qsList: [
        {
          name: "1-10",
          value: "1",
        },
        {
          name: "11-50",
          value: "2",
        },
        {
          name: "51-100",
          value: "3",
        },
        {
          name: "101-150",
          value: "4",
        },
        {
          name: "151-200",
          value: "5",
        }
      ],

      resultsList: [
        {
          name: "全部",
          value: ""
        },
        {
          name: "成功案例",
          value: "0"
        },
        {
          name: "失败案例",
          value: "1"
        }
      ],
      LEVELList: [],
      areasList: [],
      combinationList: [
        {
          name: "全部",
          value: ""
        },
        {
          name: "G5",
          value: "0"
        },
        {
          name: "王曼爱华",
          value: "1"
        },
      ],
      collegeList: [],
      selectedValue: '',
      loading: false,
      chooseCollege: {},
      schoolType: '',

    };
  },
  mounted() {
    this.getCodeList('ZXDQ');
    this.getCodeList('ZYFX');
    this.getCodeList('LEVEL');
  },
  methods: {
    onSubmit() {
      console.log(this.form);
      // if (this.form.majorNameList != "") {
      //   if (this.form.sonClass == "") {
      //     this.$message({
      //       type: 'error',
      //       message: '请选择子类专业'
      //     });
      //   return;
      //   }
      // }
      const data = { ...this.form };
      if (this.form.checked === false) {
        data.levelId = this.schoolType;
         delete data.bkSchoolName;
        } else {
          delete data.levelId;
        }
      delete data.qs;
      delete data.checked;
      delete data.majorNameList;
      getCaseInfoList(data).then(res => {
        if (res.code == '2000') {
            if (res.data.list.length != 0) {
              this.$router.push({
                path: '/caseDetails',
                query: { datas: JSON.parse(JSON.stringify(data)) }
              });
            } else {
              this.$message({
                type: 'error',
                message: '没有符合条件的案例，请重新选择条件。'
              });
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      // this.$router.push({
      //   path: '/caseDetails'
      // });
      // console.log("submit!");
    },
    resetForm(formName) {
      if (this.$refs[formName]) {
          this.$refs[formName].resetFields();
          this.form = this.$options.data().form;
        } else {
          console.warn(`Form with name ${formName} does not exist.`);
        }
    },
    getCodeList(CODE) {
        dicListByCode({ code: CODE })
          .then(res => {
            if (res.code == '2000') {
              this[CODE + 'List'].unshift(...res.data);
              if (CODE == 'LEVEL') {
                this.LEVELList = [{ id: '', name: '全部' }, ...res.data];
              }
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            this.$message({
              type: 'error',
              message: '请求过程中发生错误，请稍后再试。'
            });
          });
    },
    changepro(val) {
      this.form.sonClass = '';
      if (val !== '') {
          dicListByCode({ code: val })
          .then(res => {
            if (res.code == '2000') {
              this.professionalsonList = res.data;
              console.log( this.ZYFXList);
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            this.$message({
              type: 'error',
              message: '请求过程中发生错误，请稍后再试。'
            });
          });
          return true;
        }
    },
    changeQs(val) {
      this.qsList.map((item) => {
        if (item.value == val) {
          const parts = item.name.split('-');
          this.form.startQsRank = parts[0];
          this.form.endQsRank = parts[1];
        }
      });
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        this.form.levelId = null;
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.collegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    },
    bkSchoolNameChange(val) {
      this.form.levelId = null;
      this.collegeList = this.collegeList.filter((item) => {
        if (item.name == val) {
          this.schoolType = item.schoolType;
          return true;
        }
      });
    },
    changeLevel(val) {
        this.form.bkSchoolName = '';
    },
  },
};
</script>

<style style lang="stylus" scoped>
@import '~css/stylus.styl';
.caseshareBox {
  background-color: #f5f7fa;
  padding-bottom: 84px;
}

.topban {
  width: 100%;
  height: 140px;
  background: url('~@/assets/share/share_banner1.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index:1;
}

.topban_left {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      display: block;
      margin-right: 12px;
    }

    img:nth-of-type(2) {
      margin-left: 12px;
    }
  }

  span {
    display: block;
    font-size: 14px;
    color: #FFFFFF;
    text-stroke: 1px rgba(0, 0, 0, 0);
    height: 19px;
    margin-top: 6px;
  }
}
.formbox{
  width: 999px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  padding: 50px 182px  35px 137px;
  margin: -33px auto 0;
  position:relative
  z-index:2;
  .el-select{
    width: 100%;
  }
  >>>.el-form-item{
    margin-bottom: 20px;
  }
  .inputClass{
    >>>.el-form-item__content{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .el-input{
      width:280px;
    }
    span{
      display: block;
      margin: 0 4px
    }
  }
  .tips{
    height: 16px;
    font-weight: 400;
    font-size: 12px;
    color: #FF8240;
    line-height: 16px;
    white-space: nowrap;
  }
  .el-date-editor.el-input{
    width: 100%;
  }
  .btnList{
    display: flex;
    justify-content: center;
    margin-top: 35px;
    .el-button{
      width: 172px;
      height: 50px;
      background: #FFFFFF;
      border-radius: 90px 90px 90px 90px;
      border: 1px solid $themeColor;
      padding: 0;
      text-align: center;
      font-size: 16px;
      color:$themeColor
    }
    .el-button:nth-of-type(2){
      color: #FFFFFF;
      background: $themeColor;
      margin-left: 21px;
    }
  }
}
>>>.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #333333 !important;
}
>>>.el-checkbox__input.is-checked .el-checkbox__inner{
  background: #FF8240 !important;
  border-color:#FF8240 !important;
}
>>>.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #FF8240 !important;
}
>>>.today .cell{
  color: #FF8240 !important;
}

</style>