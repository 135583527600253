import request from '@utils/request';

// Question&Answer

export function getQalist(data) {
    return request({
      url: '/mv/cmsQa/list',
      method: 'post',
      data
    });
  }