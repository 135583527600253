<template>
  <div class="homePage">
    <el-carousel arrow="never" trigger="click" v-if="setMenu.home_one && setMenu.home_one.isShow==1">
      <el-carousel-item v-for="(item, index) in bannerInfo" :key="index">
        <div class="banner-box cloumn">
          <img :src="item.fileUrl" alt="">
          <div class="banner-content">
            <!-- <div>
              <p class="banner-title">{{ item.title }}</p>
              <p class="banner-info">{{ item.subhead }}</p>
              <p class="banner-text">{{ item.contents }}</p>
              <button @click="lookDetail(item)" class="finger detail-btn">查看详情 →</button>
            </div> -->
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="flex-justify-center" >
      <div class="home-middle flex-center flexColumn">
        <!-- 院校匹配 -->
        <div class="collegeMatching flex" v-if="setMenu.home_tow && setMenu.home_tow.isShow==1">
          <img src="@/assets/yxpp.png" alt="" />
          <div class="flexBetweenCenter matching-box">
            <div class="flex-center bottom-pad">
              <p class="matching-title">
                <!-- <span class="asterisk">*</span> -->
                <span>我的学校：</span>
              </p>
              <!-- <el-input class="input-text" size="medium" v-model.trim="FormData.schoolName" placeholder="请输入学校"></el-input> -->
              <el-select
                        v-model="FormData.schoolName"
                        filterable
                        remote
                        :remote-method="remoteSearch"
                        placeholder="请输入关键词"
                        style="width:210px"
                        >
                        <el-option
                        v-for="item in choosecollegeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                        ></el-option>
                     </el-select>
            </div>
            <div class="flex-center bottom-pad">
              <p class="matching-titles">
                <span class="asterisk">*</span>
                <span>留学意向地区：</span>
              </p>
              <el-select class="input-text" size="medium" v-model="FormData.areaId" placeholder="请选择留学意向地区" @change="changeArea">
                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="flex-center bottom-pad">
              <p class="matching-title">
                <span class="asterisk">*</span>
                <span>GPA：</span>
              </p>
              <el-input class="input-text matching-input" size="medium" v-model.trim="FormData.gpa" placeholder="请输入计算服务">
                <template slot="append"><span class="finger" @click="toCount">计算服务</span></template>
              </el-input>
            </div>
            <div class="flex-center bottom-pad">
              <p class="matching-titles">
                <span class="asterisk">*</span>
                <span>意向专业方向：</span>
              </p>
              <el-input class="input-text" @focus="selectMajor" size="medium" v-model.trim="FormData.majorTxt" placeholder="请选择意向专业方向"></el-input>
              <!-- <el-select class="input-text" size="medium" v-model="value" placeholder="请选择意向专业方向">
                <el-option v-for="item in majorList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select> -->
            </div>
            <div class="flex-center" >
              <p class="matching-title">
                <span>雅思：</span>
              </p>
              <el-input class="input-text" size="medium" v-model.trim="FormData.ielts" placeholder="总分"></el-input>
            </div>
            <div class="flex-center">
              <p class="matching-title" style="width:101px">
                <span>托福：</span>
              </p>
              <el-input class="input-text" size="medium" v-model.trim="FormData.toeflTotal" placeholder="总分"></el-input>
            </div>
          </div>
          <div class="mate-btn flexCenter finger" @click="onMate">
            开始
            <br />
            匹配
          </div>
        </div>
        <!-- 院校专业库 -->
        <div class="library flex">
          <div @click="toLibraryDetail(item)" @mouseover="mouseoverParent(index, item)" @mouseout="mouseoutParent(index, item)" class="finger library-box flexBetweenCenter" v-for="(item, index) in libraryList" :key="index" :style="{ backgroundImage: 'url(' + item.image + ')' }">
            <div>
              <p class="library-title">{{ item.title }}</p>
              <p class="library-btn">查看</p>
            </div>
            <div class="icon-arrow">
              <i style="color: #FF8240" class="el-icon-right"></i>
            </div>
          </div>
        </div>
        <!-- 院校列表 -->
        <div class="college-box" v-if="setMenu.home_three && setMenu.home_three.isShow==1">
          <p class="flex-justify-center home-title">
            <span>{{setMenu.home_three.showName}}</span>
          </p>
          <div class="country-list flex-wrap">
            <p :class="[areaId == item.id ? 'activeCountry' : '', 'countryText']" v-for="(item, index) in ZXDQList" class="finger" @click="changeCountry(item)" :key="index">{{ item.name }}</p>
          </div>
          <div class="college-list flex-wrap">
            <div v-for="(item, index) in collegeList" :key="index" @click="toCollegeDetail(item)" class="college-list-item flex-center finger">
              <img :src="item.logo" alt="" />
              <div class="college-content flex-justify-center flexColumn">
                <p class="college-name">{{ item.universityNameCn }}</p>
                <span class="college-english-name">{{ item.universityNameEn }}</span>
                <div>
                  QS排名：
                  <span class="ranking">{{ item.qsRank }}名</span>
                </div>
              </div>
            </div>
            <el-empty v-if="collegeList.length == 0" description="暂无数据"></el-empty>
            <div  class="flex-justify-center look-more">
              <p class="finger" @click="toLookMore('college')">
                查看更多
                <i style="color: #666666" class="el-icon-right"></i>
              </p>
            </div>
          </div>
        </div>
        <!-- 超哥资讯 -->
        <div class="infoBox" v-if="setMenu.home_four && setMenu.home_four.isShow==1">
          <p class="flex-justify-center home-title">
            <span>{{setMenu.home_four.showName}}</span>
          </p>
          <div class="infoBox-list">
            <div class="infoBox-list-item finger" v-for="(item, index) in infoList" :key="index" @click="toLookMore('info')">
              <div class="infoBox-bgImage" :style="{ backgroundImage: 'url(' + item.picture + ')' }">
                <p>{{ item.sonClass }}</p>
              </div>
              <div>
                <p class="infoBox-title">{{ item.title }}</p>
                <p class="infoBox-text ellipsis">{{ item.content }}</p>
                <div class="flexBetween infoBox-date">
                  <p class="flexBetweenCenter">
                    <img src="@/assets/collegeicon.png" alt="" />
                    <span>{{ item.universityName }}</span>
                  </p>
                  <p class="flexBetweenCenter">
                    <img src="@/assets/dateicon.png" alt="" />
                    <span>{{ item.createTime }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="flex-justify-center look-more">
              <p class="finger" @click="toLookMore('info')">
                查看更多
                <i style="color: #666666" class="el-icon-right"></i>
              </p>
            </div>
          </div>
        </div>
        <!-- 院校排名 -->
        <div class="rankingBox flex-center-column" v-if="setMenu.home_five && setMenu.home_five.isShow==1">
          <p class="flex-justify-center home-title">
            <span>{{setMenu.home_five.showName}}</span>
          </p>
          <div class="rankingBox-list flex-wrap">
            <div class="rankingBox-list-item finger flex-center" v-for="(item, index) in rankingList" :key="index" @click="toRank(item)">
              <img v-if="item.rankPlatform==0" src="../../assets/QS.png" alt="" />
              <img v-else-if="item.rankPlatform==1" src="../../assets/US.png" alt="" />
              <img v-else-if="item.rankPlatform==2" src="../../assets/THE.png" alt="" />
              <img v-else-if="item.rankPlatform==3" src="../../assets/RANK.png" alt="" />
              <div>
                <p class="rankingBox-date">{{ item.rankYear }}</p>
                <span>{{ item.rankName }}</span>
                <!-- <span v-if="item.rankPlatform==0 && item.rankType==0" class="rankingBox-title">世界大学综合排名-总榜</span>
                <span v-if="item.rankPlatform==0 && item.rankType==1" class="rankingBox-title">世界大学专业排名</span>
                <span v-if="item.rankPlatform==1" class="rankingBox-title">U.S.News美国大学综合排名-总榜</span>
                <span v-if="item.rankPlatform==2" class="rankingBox-title">THE世界大学综合排名-总榜</span> -->
              </div>
            </div>
            <div class="flex-justify-center look-more">
              <p class="finger" @click="toLookMore('ranking')">
                查看更多
                <i style="color: #666666" class="el-icon-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <majorDialog ref="majorDialog"  @sendShowList="sendShowList" @sendDataList="sendDataList" ></majorDialog>
    <GPADialog ref="GPADialog"></GPADialog>
    <div class="custom-message-box" v-if="showTip">
      <p>即将上线，敬请期待</p>
    </div>
  </div>
</template>

<script>
import majorDialog from './components/majorDialog';
import GPADialog from '../../components/GPAcalculation.vue';
import { getCollegeList } from 'api/libraryList.js';
import { dicListByCode, getBanner } from 'api/index.js';
import { getInfoList } from 'api/information.js';
import { getmenuList } from 'api/menu.js';
import { getRank } from 'api/rank.js';
import { mapActions } from 'vuex';
import { getBkSchool } from 'api/caseSharing.js';
export default {
  name: 'HomePage',
  components: {
    majorDialog,
    GPADialog
  },
  data() {
    return {
      ZXDQList: [],
      dicCodelist: ['ZXDQ'], //  ZXDQ地区
      bannerInfo: [],
      collegeList: [], // 院校
      areaList: [], // 留学意向地区
      majorList: [],
      libraryList: [
        {
          title: '院校库',
          image: require('@/assets/yxk.png'),
          activeImage: require('@/assets/yxk1.png'),
          path: '/libraryList?source=1'
        },
        {
          title: '项目库',
          image: require('@/assets/zyk.png'),
          activeImage: require('@/assets/zyk1.png'),
          path: '/libraryList?source=2'
        },
        {
          title: '案例分享',
          image: require('@/assets/alfx.png'),
          activeImage: require('@/assets/alfx1.png'),
          path: '/caseShare'
        },
        {
          title: '超哥资讯',
          image: require('@/assets/yxzx.png'),
          activeImage: require('@/assets/yxzx1.png'),
          path: '/ininformational'
        }
      ],
      value: '',
      areaId: '',
      infoList: [],
      rankingList: [],
      mouseIndex: '',
      input: '',
      setMenu: {
        home_one: { isShow: 1 },
        home_two: { isShow: 1 },
        home_three: { isShow: 1 },
        home_four: { isShow: 1 },
        home_five: { isShow: 1 }

      },
      FormData: {
        areaId: '',
        majorId: '',
        majorList: [],
        ielts: '',
        schoolName: '',
        toeflTotal: "",
        majorTxt: []
      },
      areaName: "",
      sendData: [],
      showTip: false,
      choosecollegeList: []

    };
  },
  methods: {
    sendDataList(data) {
      this.sendData = data;
    },
    sendShowList(data) {
      this.FormData.majorTxt = data;
    },
    ...mapActions(['trackEvent']),
    ...mapActions(['trackEvent']),
    getInfoList() {
      const data = {
        ifPage: true,
        pageSize: 3,
        pageNum: 1
      };
      getInfoList(data).then(res => {
        if (res.code == '2000') {
          this.infoList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getCodeList() {
      this.dicCodelist.forEach(item => {
        dicListByCode({ code: item })
          .then(res => {
            if (res.code == '2000') {
              this[item + 'List'].unshift(...res.data);
              if (item == 'ZXDQ') {
                this.areaId = this[item + 'List'][0].id;
                this.getCollegeList();
                this.areaList = res.data;
              }
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            this.$message({
              type: 'error',
              message: '请求过程中发生错误，请稍后再试。'
            });
          });
      });
    },

    getCollegeList() {
      const data = {
        ifPage: true,
        pageSize: 16,
        pageNum: 1,
        areaId: this.areaId
      };
      getCollegeList(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    selectMajor() {
      this.$refs.majorDialog.openDialog();
    },
    toLookMore(type) {
      let path = 'type';
      if (type == 'college') {
        path = '/libraryList?source=1';
} else if (type == 'info') {
        path = '/ininformational';
        this.trackEvent({ action: '超哥资讯页' });
      } else if (type == 'ranking') {
        path = '/universityRanking';
        this.trackEvent({ action: '院校排名页' });
      }
      this.$router.push({
        path
      });
    },
    changeCountry(val) {
      this.areaId = val.id;
      this.getCollegeList();
    },
    toCollegeDetail(val) {
      this.$router.push({
        path: '/professionalList',
        query: {
          schoolId: val.id
        }
      });
    },
    toCount() {
      this.$refs.GPADialog.openDialog();
    },
    onMate() {
      if (this.FormData.gpa == '') {
        this.$message({
          type: 'error',
          message: '请输入GPA'
        });
        return false;
      }
      if (this.FormData.areaId == '') {
        this.$message({
          type: 'error',
          message: '清选择留学意向地区'
        });
        return false;
      }
      if (this.FormData.majorTxt == '') {
        this.$message({
          type: 'error',
          message: '请选择意向专业方向'
        });
        return false;
      }

      this.$router.push({
        path: '/collegeMatching',
        query: {
          searchObj: this.FormData,
          chooseList: this.sendData,

        }
      });
    },
    lookDetail(itme) {
      this.$router.push({
        path: '/bannerDetail',
        query: {
          id: itme.id
        }
      });
    },
    toLibraryDetail(val) {
      if (val.path == '/caseShare') {
        this.showTip = true;
        setTimeout(() => {
          this.showTip = false;
        }, 2000);

        return false;
      } else if (val.path == '/ininformational') {
        this.trackEvent({ action: '超哥资讯页' });
      }
      this.$router.push({
        path: val.path
      });
    },
    mouseoverParent(index, item) {
      const i = index + 1;
      this.mouseIndex = index;
      const style = `background-image: url(${item.activeImage});`;
      document.querySelector(`.library > div:nth-child(${i})`).style = style;
      document.querySelector(`.library > div:nth-child(${i}) .library-btn`).style = 'background:#ff8240;color:#fff';
      document.querySelector(`.library > div:nth-child(${i}) .icon-arrow`).style = 'background:#ff8240;';
      document.querySelector(`.library > div:nth-child(${i}) .icon-arrow i`).style = 'color:#fff';
    },
    mouseoutParent(index, item) {
      const i = index + 1;
      this.mouseIndex = -1;
      const style = `background-image: url(${item.image})`;
      document.querySelector(`.library > div:nth-child(${i})`).style = style;
      document.querySelector(`.library > div:nth-child(${i}) .library-btn`).style = 'background:#EEEEEE;color:#999999';
      document.querySelector(`.library > div:nth-child(${i}) .icon-arrow`).style = 'background:#fff;';
      document.querySelector(`.library > div:nth-child(${i}) .icon-arrow i`).style = 'color:#ff8240';
    },
    getMenu() {
      const data = {
        code: 'Home'
      };
      getmenuList(data).then(res => {
            if (res.code == 2000) {
                // this.$store.commit('setMenu',res.data)
                console.log(res.data);
                this.setMenu = res.data;
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: err.msg
            });
        });
    },
    getRank() {
      const data = {
        pageSize: 6,
        ifPage: false,
        pageNum: 1,
        rankType: 0
      };
      getRank(data).then(res => {
            if (res.code == 2000) {
                this.rankingList = res.data.list;
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: err.msg
            });
        });
    },
    toRank(item) {
      this.$router.push({
        path: '/universityRanking',
        query: {
          rankYear: item.rankYear,
          rankPlatform: item.rankPlatform,
          rankType: item.rankType,
        }
      });
      this.trackEvent({ action: '院校排名页' });
    },
    getBanners() {
      const data = {
        ifPage: false,
        source: 1
      };
      getBanner(data).then(res => {
        if (res.code == '2000') {
          this.bannerInfo = res.data.list || [];
          this.bannerInfo.forEach((item, index) => {
            const newlineIndex = item.introduction.indexOf('\n');
            item.subhead = item.introduction.substring(0, newlineIndex);
            item.contents = item.introduction.substring(newlineIndex + 1);
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    changeArea() {
      let chooseArea = [];
      chooseArea = this.areaList.filter(item => item.id == this.FormData.areaId);
      this.areaName = chooseArea[0].name;
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.choosecollegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.choosecollegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    }
  },
  mounted() {
    this.getMenu();
    this.getCodeList();
    this.getInfoList();
    this.getRank();
    this.getBanners();
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

/deep/.el-carousel__container {
  height: 400px;
}

.look-more {
  width: 100%;
  margin-top: 10px;

  &>p {
    width: 140px;
    line-height: 40px;
    border-radius: 90px 90px 90px 90px;
    border: 1px solid #CCCCCC;
    color: #666666;
    text-align: center;
  }
}

.home-title>span {
  width: 130px;
  font-weight: bold;
  font-size: 30px;
  background: url('../../assets/title-icon.png') no-repeat;
  background-size: 36px 38px;
  background-position: 0px 3px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.banner-box {
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  img{
    height: 100%;
    width: 100%;
  }
  .detail-btn {
    font-size: 14px;
  }

  .banner-content {
    width: $width;
    box-sizing: border-box;
    padding-left: 72px;
    position: absolute;
    top: 60px;
    left: 180px;
    &>div {
      width: 44%;
    }
  }

  p {
    padding-bottom: 20px;
  }

  .banner-title {
    font-size: 34px;
  }

  .banner-info {
    font-size: 24px;
  }

  .banner-text {
    font-size: 18px;
    line-height: 30px;
  }

  button {
    width: 150px;
    height: 40px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #FFFFFF;
    color: #fff;
    margin-top: 10px;
  }
}

.home-middle {
  width: 100%;
  z-index: 5;
  background: #FFFFFF;

  &>div {
    width: $width;
  }

  &>div:last-child {
    width: 100%;
  }

  .collegeMatching, .library {
    width: 1100px;
  }

  .collegeMatching {
    margin: -60px 50px 0;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 12px 12px;

    .bottom-pad {
      margin-bottom: 10px;
    }

    .mate-btn {
      width: 140px;
      background: $themeColor;
      border-radius: 0px 12px 12px 0px;
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 32px;
    }

    &>img {
      width: 71px;
      height: 83px;
      margin: 35px 105px 0 39px;
    }

    .matching-box {
      flex: 1;
      flex-wrap: wrap;
      padding: 20px 0;
      margin-right: 93px;

      .matching-title {
        width: 80px;
      }

      .matching-titles {
        width: 108px;
      }
    }
  }

  .asterisk {
    color: $asteriskColor;
  }

  .input-text {
    width: 210px;
  }

  .library {
    height: 100px;
    margin: 40px 50px 0;
    box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .icon-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 14px 14px 14px 14px;
      border: 1px solid $themeColor;
      background: #fff;
      transition: all 0.3s;
    }

    .library-title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .library-btn {
      width: 60px;
      line-height: 20px;
      background: #EEEEEE;
      border-radius: 14px 14px 14px 14px;
      text-align: center;
      color: #999999;
      transition: all 0.3s;
      height: 20px;
    }

    .library-box {
      width: 25%;
      height: 100%;
      padding: 0 15px 0 40px;
      background-size: cover;
      background-position: center;
      transition: all 0.3s;
    }
  }

  .college-box {
    margin-top:47px;
    .country-list {
      margin:30px 0;
      justify-content: space-around;

      .countryText {
        color: #666666;
        font-size: 20px;
        line-height: 38px;
        text-align: center;
        padding: 0 22px;
        transition: all 0.3s;
        border-radius: 19px 19px 19px 19px;
        font-weight: bold;
      }
      .countryText:hover{
        background: #FFEFE6;
        color: $themeColor;

        cursor: pointer;
      }

      p.activeCountry {
        background: #FFEFE6;
        color: $themeColor;
        border-radius: 19px 19px 19px 19px;
      }
    }

    .college-list {
      .college-list-item {
        width: 285px;
        height: 116px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #EEEEEE;
        margin: 0 20px 20px 0;
        box-sizing: border-box;
        transition: all 0.3s;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      .college-list-item:hover {
        box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
        border-color: #fff;
       }
      .college-name {
        font-weight: bold;
        font-size: 18px;
      }

      .college-english-name {
        padding: 8px 0;
      }

      .ranking {
        font-weight: bold;
        color: #FF772E;
      }

      .college-content {
        height: 100%;
      }

      img {
        width: 60px;
        height: 60px;
        margin: 0 15px;
      }
    }
  }

  .infoBox {

    .infoBox-list{
      justify-content: center;
    }
    .infoBox-list-item {
      width: 380px;
      min-height: 355px;
      float: left;
      margin: 0 30px 0 0;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      color: #666666;
      margin: 0 30px 20px 0;
      box-sizing: border-box;
      transition: all 0.3s;
      &:nth-child(3n) {
        margin-right: 0;
      }

      &>div {
        padding: 15px 22px 19px 20px;
        border: 1px solid #eee;
        border-radius: 0 0 12px 12px;
        border-top: none;
      }

      .infoBox-title {
        line-height: 32px;
        color: #333;
        font-weight: bold;
        font-size: 18px;
      }

      .infoBox-text {
        margin: 9px 0 15px;
        line-height: 20px;
        height:40px
      }

      .infoBox-bgImage {
        height: 174px;
        background-size: cover;
        background-position: center;
        border-radius: 12px 12px 0 0;
        background-repeat: no-repeat;
        position: relative;

        p{
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #FFFFFF;
          padding:0 7px;
          line-height: 24px;
          color: #FFFFFF;
          display: inline-block;
          position: relative;
          z-index: 3;
        }
      }
      .infoBox-bgImage:after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 72px;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0) 100%);
        border-radius: 12px 12px 0 0;
      }
      .infoBox-date {
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
    .infoBox-list-item:nth-child(3) {
      margin-right: 0;
    }
    .infoBox-list-item:hover {
      box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
      border-color: #fff;
    }
    .home-title {
      margin: 70px 0 50px;
    }
  }
  .infoBox:after {
    content: "";
    display: block;
    clear: both;
   }
  .rankingBox {
    background: url('../../assets/ranking.png') no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 72px;
    margin-top: 67px;
    .home-title {
      padding: 50px 0;
    }

    .rankingBox-list {
      width: $width;
    }

    .rankingBox-list-item {
      width: 380px;
      height: 80px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #EEEEEE;
      margin: 0 30px 30px 0;
      color: #333333;
      box-sizing: border-box;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .rankingBox-date {
        font-weight: bold;
        line-height: 32px;
        font-size: 16px;
      }

      .rankingBox-text {
        font-size: 16px;
      }

      img {
        width: 46px;
        height: 46px;
        margin: 17px 14px;
      }
    }
  }
}

>>>.el-input-group__append {
  background: #fff;
  color: $themeColor;
  padding: 0 11px;
}

.matching-input >>>.el-input__inner {

}

>>>.el-input__inner:focus {
  outline: none;
  border-color: #DCDFE6;
}
>>>.el-carousel__indicators--horizontal{
  bottom: 65px !important;
}
.el-empty{
  margin:0 auto !important;
}
.custom-message-box {
  background-color: rgba(0, 0, 0, 0.7) !important; /* 黑色透明背景，透明度为0.7 */
  color: #fff; /* 白色文字 */
  width: 250px;
  height: 50px;
  border-radius: 8px 8px 8px 8px;
  line-height: 50px;
  text-align: center;
  position: fixed; /* 固定定位 */
  top: 50%; /* 上边距为页面高度的1/2 */
  left: 50%; /* 左边距为页面宽度的1/2 */
  transform: translate(-50%, -50%); /* 水平垂直居中 */
  z-index: 999; /* 设置层级 */
}
</style>
<style>
#app{
    background-color: #Fff;
}
</style>