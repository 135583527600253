import request from '@utils/request';
// 上传图片
export function uploadFile(data, onUploadProgress) {
  return request({
    url: '/mv/upload/uploadFile',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) { // onUploadProgress是上传进度
        onUploadProgress(progressEvent);
      }
    },
    data
  }, false);
}
// 字典详情
export function dicListByCode(data) {
  return request({
    url: `/move/sys/dic/listByCode`,
    method: 'post',
    data
  });
}
export function getBanner(data) {
  return request({
    url: `/move/sysBanner/list`,
    method: 'post',
    data
  });
}
export function getBannerDetail(data) {
  return request({
    url: `/move/sysBanner/detail`,
    method: 'post',
    data
  });
}
// GPA
export function submitOrder(data) {
  return request({
    url: `/mv/cmsCommonOrder/submitOrder`,
    method: 'post',
    data
  });
}
export function Orderdetail(data) {
  return request({
    url: `/mv/cmsCommonOrder/detail`,
    method: 'post',
    data
  });
}
export function getOrderlist(data) {
  return request({
    url: `/mv/cmsCommonOrder/list`,
    method: 'post',
    data
  });
}
export function cmsBuryingPointAdd(data) {
  return request({
    url: `/mv/cmsBuryingPoint/add`,
    method: 'post',
    data
  });
}
export function cmsUniversity(data) {
  return request({
    url: `/mv/cmsUniversity/indexList`,
    method: 'post',
    data
  });
}
export function getDictTreeByCode(data) {
  return request({
    url: `/move/sys/dic/getDictTreeByCode`,
    method: 'post',
    data
  });
}

