import request from '@utils/request';
// 获取案例库列表
export function getCaseList(data) {
  return request({
    url: '/mv/cmsCaseInfo/list',
    method: 'post',
    data
  });
}
// 案例库详情
export function getCaseDetail(data) {
  return request({
    url: '/mv/cmsCaseInfo/detail',
    method: 'post',
    data
  });
}
// 案例库详情
export function getCaseInfoList(data) {
  return request({
    url: '/mv/cmsCaseInfo/caseInfoList',
    method: 'post',
    data
  });
}
export function getBkSchool(data) {
  return request({
    url: '/mv/cmsBkSchool/list',
    method: 'post',
    data
  });
}
