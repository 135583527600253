import {
  enc
} from '@utils/encryptedFile/des.js';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {
  getToken
} from '@/utils/auth';
// import {
//   Message
// } from 'element-ui';
// import store from '@/store';
// 参数加密 (解密在vue-router依赖中添加 详见README.md)
function parameterEnc(location, onComplete, onAbort) {
  const locationObj = JSON.parse(JSON.stringify(location));
  switch (typeof locationObj) {
    case 'string':
      break;
    default:
      if (locationObj.query && JSON.stringify(locationObj.query) != '{}') {
        for (const key in locationObj.query) {
          locationObj.query[key] = enc(locationObj.query[key]);
        };
      };
      if (locationObj.params && JSON.stringify(locationObj.params) != '{}') {
        for (const key in locationObj.params) {
          const paramsEncStr = enc(locationObj.params[key]);
          if (locationObj.path) {
            const paramsPath = locationObj.path.split('/');
            for (let i = 0; i < paramsPath.length; i++) {
              if (paramsPath[i] == locationObj.params[key]) {
                paramsPath[i] = paramsEncStr;
              }
            }
            locationObj.path = paramsPath.join('/');
          }
          locationObj.params[key] = paramsEncStr;
        };
      };
      break;
  };
  return [locationObj, onComplete, onAbort];
};

// vue-router
const routerFun = (Router, routes) => {
  const routerPush = Router.prototype.push;
  const routerReplace = Router.prototype.replace;
  // 重写router.push方法   在调用push前加密参数
  Router.prototype.push = function (location, onComplete, onAbort) {
    return routerPush.apply(this, parameterEnc(location, onComplete, onAbort));
  };
  // 重写router.replace   在调用replace前加密参数
  Router.prototype.replace = function (location, onComplete, onAbort) {
    return routerReplace.apply(this, parameterEnc(location, onComplete, onAbort));
  };

  const router = new Router({
    mode: 'hash',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 };
    }
  });

  // 全局前置路由守卫
  router.beforeEach((to, from, next) => {
    NProgress.start();

    // 判断是否登录
    // 判断是否已获取到路由数据 // 如果没有路由数据  先获取路由数据 后跳转
    // store.dispatch('setToPath', to.meta.title);
    // sessionStorage.setItem('toPath', store.state.path.toPath);
    if (to.path == '/') {
      next();
    } else {
      next();

      if (getToken()) {
        // const limits = JSON.parse(sessionStorage.getItem('limits'));
        // const exists = limits.some(el => el == to.meta.limit);
        // if (to.meta.limit && !exists) {
        //   Message({
        //     type: 'error',
        //     message: '暂无权限!!!'
        //   });
        //   NProgress.done();
        //   // next(from.path);
        // } else {
        //   next();
        // }
        next();
      } else {
        // store.dispatch('setPatient', {});
        // store.dispatch('setCardNo', {});
        // store.dispatch('setPatientCardList', []);
        // sessionStorage.clear();
        // Message({
        //   type: 'error',
        //   message: '请先登录!!!'
        // });
        // next('/');
      }
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  });
  //
  router.afterEach((to, from) => {
    NProgress.done();
  });
  return router;
};

export default routerFun;
