<template>
  <div class="jyt-container">
    <el-container direction="vertical" class="bodyContainer">
      <el-header style="position: sticky; top: 0;z-index: 10;" class="header">
        <Header></Header>
      </el-header>
      <!-- <el-container class="displayFlex"> -->
      <el-main class="main">
        <router-view :key="$route.fullPath" />
        <Footer></Footer>
      </el-main>
      <!-- </el-container> -->
    </el-container>
  </div>
</template>
<script>
import Header from '../components/header';
import Footer from '../components/footer';
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="stylus" scoped>
.jyt-container {
  width: 100%;

  .el-container {
    width: 100%;
    height: 100%;
  }
}

.header {
  height: 50px !important;
  width: 100%;
  background-color: #fff;
}

.displayFlex {
  display: flex;
  background-color: #f8f8f8;
  height: calc(100vh - 60px) !important;
}

>>>.el-main,>>>.el-footer {
  padding: 0 !important;
}

.main {
  box-sizing: border-box;

}

::v-deep .el-dialog__footer {
  text-align: center !important;
}
</style>