import { render, staticRenderFns } from "./personalData.vue?vue&type=template&id=651c7dbc&scoped=true"
import script from "./personalData.vue?vue&type=script&lang=js"
export * from "./personalData.vue?vue&type=script&lang=js"
import style0 from "./personalData.vue?vue&type=style&index=0&id=651c7dbc&prod&lang=stylus&scoped=true"
import style1 from "./personalData.vue?vue&type=style&index=1&id=651c7dbc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651c7dbc",
  null
  
)

export default component.exports