const filter = {
  // contactTypeFilter(val) {
  //   switch (val) {
  //     case "0":
  //       return "电话联系";
  //     case "1":
  //       return "微信联系";
  //     case "2":
  //       return "面谈";
  //     default:
  //       return "—— ——";
  //   }
  // }
};
export default filter;
