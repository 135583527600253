import request from '@utils/request';

// 获取学校列表
export function getCollegeList(data) {
  return request({
    url: '/mv/cmsUniversity/list',
    method: 'post',
    data
  });
}
// 学校详情
export function getCollegeDetail(data) {
  return request({
    url: '/mv/cmsUniversity/detail',
    method: 'post',
    data
  });
}
// 获取专业列表
export function getMajorList(data) {
  return request({
    url: '/mv/cmsProgram/list',
    method: 'post',
    data
  });
}
//
export function getMajorRankList(data) {
  return request({
    url: '/mv/cmsProgram/programWithMajorRanklist',
    method: 'post',
    data
  });
}
// 学校详情
export function getMajorDetail(data) {
  return request({
    url: '/mv/cmsMajor/detail',
    method: 'post',
    data
  });
}

// 新增收藏
export function addCollection(data) {
  return request({
    url: '/mv/cmsUserCollection/add',
    method: 'post',
    data
  });
}

export function doCollection(data) {
  return request({
    url: '/mv/cmsUserCollection/doCollection',
    method: 'post',
    data
  });
}
export function analysisCaseInfo(data) {
  return request({
    url: '/mv/cmsProgram/analysisCaseInfo',
    method: 'post',
    data
  });
}

// 项目详情
export function getcmsProgram(data) {
  return request({
    url: '/mv/cmsProgram/detail',
    method: 'post',
    data
  });
}