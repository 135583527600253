<template>
  <div class="map">
    <headerTitle
      title="首页"
      :titles="['院校匹配','地图详情']"
      :showBtn="true"
      :translate="true"
      @back="back"
    ></headerTitle>
    <div class="map_content">
        <div class="title">
            <span></span>
            <p>已匹配的院校列表</p>
        </div>
        <div class="mapList">
        <div class="mapLeft">
            <div class="collageBox" v-for="(item,index) in collageList" :key="index">
                <p class="icons">{{ index+1 }}</p>
                <img :src="item.logo" @click="toDetail(item)" alt="">
                <p class="introduce">
                    <span  @click="toDetail(item)">{{ item.universityNameCn}} | {{ item.universityNameEn }}</span>
                    <span  @click="toAddress(item)">地址：{{ item.addressCn }}</span>
                    <span @click="toAddress(item)">Address：{{ item.address }}</span>
                </p>
            </div>
        </div>
        <div class="mapRight">
            <div class="maps" id="allmap"></div>
        </div>
    </div>
    </div>

  </div>
</template>
<script src="//api.map.baidu.com/api?type=webgl&v=1.0&ak=CxN6aJlgWO3u4eEqnphOuzy0Z0fjgcOJ"></script>
<script>

import headerTitle from '@/components/title.vue';
import {getCollegeList} from '@/assets/api/libraryList.js'
export default {
    components: {
        headerTitle,
    },
    data() {
        return {
            collageList: [

            ],
            map: null,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        getData() {
            const data = {
                ifPage: true,
                pageSize: 10,
                pageNum: 1,
                mapList:this.$route.query.chooseSchool
            };
            getCollegeList(data).then(res => {
                if (res.code == '2000') {
                this.collageList = res.data.list || [];
                this.initMapEarth(this.collageList);
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                }
            });
        },
        initMapEarth(data) {
            // 创建Map实例
            this.map = new BMapGL.Map('allmap'); // 创建Map实例
            this.map.centerAndZoom(new BMapGL.Point(data[0].lng, data[0].lat), 3); // 初始化地图,设置中心点坐标和地图级别
            this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
            this.map.setMapType(BMAP_EARTH_MAP);
            data.forEach((item, index) => {
                var point = new BMapGL.Point(item.lng, item.lat); // 创建点坐标
                var marker = new BMapGL.Marker(point); // 创建标记点
                this.map.addOverlay(marker); // 将标记点添加到地图上
                var label = new BMapGL.Label(index+1, {
                //调整数字跟点标记的位置关系
                offset: new BMapGL.Size(-5, -20),
                });
                label.setStyle({
                    background: "none",
                    color: "#fff",
                    border: "none",
                });
                //对label的样式进行设置
                marker.setLabel(label);
                // 为标记点添加点击事件监听器，点击时调整地图中心和缩放级别
                marker.addEventListener('click', function() {
                    map.centerAndZoom(point, 14); // 使用当前点的坐标进行中心和缩放调整
                });
            });
        },
        toDetail(item) {
            this.$router.push({
                path: '/professionalList',
                query: {
                    schoolId: item.id
                },
            });
        },
        //点击地址  地图跳到对应位置

        toAddress(row){
            if (this.map) { // 检查地图实例是否存在
                var point = new BMapGL.Point(row.lng, row.lat);
                 this.map.centerAndZoom(point, 14);// 使用已存在的地图实例
            }

        }
    },

};
</script>

<style   lang='stylus' scoped>
@import '~css/stylus.styl';
.map{
    width: 100%;
    background: #F5F7FA url('../../assets/mapBan.png') no-repeat;
    background-size: contain;
    padding-bottom: $padding-bottom;
}
.map_content{
    width 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    .title{
        width: 100%;
        height: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 17px
        span{
            display: inline-block;
            width: 4px;
            height: 20px;
            background: $themeColor;;
            border-radius: 2px;
        }
        p{
            font-weight: bold;
            font-size: 20px;
            color: #333333;
            margin-left: 10px
        }
    }
}
.mapList{
    width 100%;
    margin: 0 auto;
    .mapLeft{
        width: 619px;
        float: left;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #EEEEEE;
        max-height: 780px;
        overflow-y: scroll;
        .collageBox{
            display: flex;
            align-items: center;
            padding: 22px 20px 22px 20px;
            border-bottom: 1px solid #EEEEEE;
            cursor:pointer;
            .icons{
                background: url('../../assets/positionicon.png') no-repeat;
                width: 22px;
                height: 28px;
                text-align: center;
                color: #fff
                line-height: 26px;
                font-weight: bold;
                font-size: 12px;
                margin-right: 15px;
                display: inline-block;
            }
            img{
                width: 70px;
                height: 70px;
                margin-right: 15px
            }
            .introduce{
                span:nth-of-type(1){
                    font-weight: bold;
                    font-size: 16px;
                    color: #5574D8;
                    margin-bottom: 8px;
                    display: block;
                    text-decoration-line: underline;
                    text-transform: none;
                    width: 450px; /* 设置元素的固定宽度 */
                    white-space: nowrap; /* 禁止文本换行 */
                    overflow: hidden;    /* 隐藏超出部分 */
                    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */

                 }
                 span:nth-of-type(2){
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 8px;
                    display: block;
                 }
                 span:nth-of-type(3){
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    display: block;

                 }
            }
        }
        .collageBox:last-child{
            border: none;
        }

    }
    .mapRight{
        width: 522px;
        float: right;
    }
}
.mapList::after{
    content: '';
    display: block;
    clear: both;
 }
 .maps {
    width: 522px;
    height: 522px;

    .bm-view {
        width: 522px;
        height: 522px;
    }
  }
</style>