<template>
  <div class="bannerDetail">
    <headerTitle title="首页" :titles="['推广']" :showBtn="true"></headerTitle>
    <div class="flex-justify-center">
      <div>
        <div class="banner-title flexBetweenCenter">
          <div v-html="bannerInfo.content" style="width: 100%"></div>
          <!-- <div class="flex hospital-info"> -->
            <!-- <img :src="bannerInfo.img" alt="" />
            <div>
              <p>{{ bannerInfo.name }}</p>
              <div class="label-text">
                <span v-for="(item, index) in bannerInfo.labelList" :key="index">{{ item }}</span>
              </div>
            </div> -->
          <!-- </div> -->
          <!-- <div class="flex-center-column wxCode">
            <img :src="bannerInfo.img" alt="" />
            <span>微信扫码联系客服</span>
          </div> -->
        </div>
        <!-- <div class="banner-box">
          <el-menu text-color="#333333" active-text-color="$themeColor" :default-active="activeMenu" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">简介</el-menu-item>
            <el-menu-item index="2">申请信息</el-menu-item>
            <el-menu-item index="3">招生专业</el-menu-item>
          </el-menu>
          <div id="content-1">
            <p class="banner-box-title">
              <span></span>
              简介
            </p>
            <span>
              {{ bannerInfo.name }}
            </span>
          </div>
          <div id="content-2">
            <p class="banner-box-title">
              <span></span>
              申请信息
            </p>
            <div class="banner-box-item">
              <p>招生对象</p>
              {{ bannerInfo.name }}
            </div>
            <div class="banner-box-item">
              <p>培养模式</p>
              {{ bannerInfo.name }}
            </div>
            <div class="banner-box-item">
              <p>归国政策</p>
              {{ bannerInfo.name }}
              <p>录取规则</p>
              {{ bannerInfo.name }}
            </div>
            <div class="banner-box-item">
              <p>证书和学位</p>
              {{ bannerInfo.name }}
            </div>
            <div class="banner-box-item">
              <p>学校地址</p>
              {{ bannerInfo.name }}
            </div>
          </div>
          <div id="content-3">
            <p class="banner-box-title">
              <span></span>
              招生专业
            </p>
            <el-table stripe :data="tableData" style="width: 100%" border>
              <el-table-column prop="date" label="专业"></el-table-column>
              <el-table-column prop="name" label="学费"></el-table-column>
              <el-table-column prop="address" label="招生计划"></el-table-column>
            </el-table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import { getBannerDetail } from 'api/index.js';
export default {
  name: 'BannerDetail',
  components: {
    headerTitle
  },
  data() {
    return {
      activeMenu: '1', // 默认激活的菜单项
      tableData: [{ date: '11' }, { date: '11' }],
      bannerInfo: {

      }
    };
  },
  methods: {
    handleSelect(index) {
      const element = document.getElementById(`content-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    getDetail() {
      const data = {
        id: this.$route.query.id
      };
      getBannerDetail(data).then(res => {
        if (res.code == '2000') {
          console.log(res.data);
          this.bannerInfo = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  },
  mounted() {
    this.getDetail();
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.bannerDetail {
  width: 100%;
  background: #F5F7FA url('../../assets/bannerDetail.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .banner-title, .banner-box {
    box-sizing: border-box;
    width: $width;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .banner-title {
   padding: 28px 20px;

    .hospital-info {
      &>div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        p {
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
      }

      img {
        width: 70px;
        height: 70px;
        border-radius: 150px 150px 150px 150px;
        margin-right: 21px;
      }

      .label-text {
        span {
          padding: 0 11px;
          height: 28px;
          background: #9EE0D3;
          border-radius: 4px 4px 4px 4px;
          margin-right: 16px;
          color: #FFFFFF;
        }
      }
    }

    .wxCode {
      font-size: 12px;

      img {
        width: 71px;
        height: 73px;
      }

      span {
        padding-bottom: 4px;
      }
    }
  }

  .banner-box {
    line-height: 26px;
    padding-bottom: 28px;

    .banner-box-title {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      line-height: 32px;
      padding: 30px 0 20px;
      display: flex;
      align-items: center;

      &>span {
        line-height: 32px;
        display: inline-block;
        color: $themeColor
        width: 4px;
        height: 20px;
        background: $themeColor
        border-radius: 90px 90px 90px 90px;
        margin-right: 8px;
      }
    }

    .banner-box-item {
      padding-bottom: 20px;

      p {
        font-size: 20px;
      }
    }
  }
}

/deep/ .el-menu-item {
  font-size: 18px;
  padding: 0;
  margin: 0 50px;
}

/deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 4px solid #409EFF;
}
</style>