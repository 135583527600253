<template>
  <div class="boxHead">
    <div class="professional-search flex-center">
      <img :src="dataInfo.logo" alt="" />
      <div class="schoolInfo">
        <div class="flexBetween">
          <div class="flex infoleft">
            <p class="professional-name">{{ dataInfo.universityNameCn }} | {{dataInfo.universityNameEn}}</p>
            <p class="professional-name">{{ professionalInfo.collegeName }}</p>
            <p class="professional-name">{{ professionalInfo.name }}</p>
            <div class="flex professional-tag">
              <p v-if="dataInfo.area"> {{ dataInfo.area }}</p>
              <p v-if="professionalInfo.qsRank!=null">专业QS排名：{{ professionalInfo.qsRank }}</p>
              <p v-if="!Id">QS排名:{{ dataInfo.qsRank }}</p>
              <p v-if="!Id">U.S.News排名：{{ dataInfo.usnewsRank }}</p>
              <p v-if="!Id">THE排名：{{ dataInfo.theRank }}</p>
              <p v-if="!Id">Rank排名：{{ dataInfo.ruankeRank }}</p>
            </div>
          </div>
          <div class="query-btn">
            <!-- <el-button @click="onQuery" class="query-score">查询该专业均分要求</el-button> -->
            <el-button @click="showComparison" class="contrast" v-if="isContrast">
              项目排名对比
            </el-button>
            <el-button @click="onCollect(dataInfoPro)" class="collect" v-if="Id">
              收藏
              <i style="color: #fff" class="el-icon-star-on" v-if="dataInfoPro.collFalg==true"></i>
              <i style="color: #fff" class="el-icon-star-off" v-else></i>
            </el-button>
            <el-button @click="onCollect(dataInfo)" class="collect" v-else>
              收藏
              <i style="color: #fff" class="el-icon-star-on" v-if="dataInfo.collFalg==true"></i>
              <i style="color: #fff" class="el-icon-star-off" v-else></i>
            </el-button>
          </div>
        </div>
        <p class="professional-info">{{ dataInfo.introduction }}</p>
        <p class="professional-btn finger" @click="toDetail">查看详情 ></p>
      </div>
    </div>
    <!--  -->
    <el-dialog center :title="'均分要求：'+dataInfo.score+'分  /  最新录取要求请参考官网'" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="500px">
      <div class="dialog flexCenter">
        <div class="flex-center">
          <span>本科学校名称：</span>
          <el-input style="width: 294px" size="medium" type="text" placeholder="例如：山西大学"></el-input>
        </div>
        <div class="flex-center">
          <span>特殊专业：</span>
          <el-input style="width: 294px" size="medium" type="text" placeholder="请输入本科学校后查看"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancel" type="info" round @click="dialogVisible = false">取 消</el-button>
        <el-button class="okBtn" type="primary" round @click="onSearch">查询</el-button>
      </div>
    </el-dialog>
        <!-- 项目排名对比弹窗 -->
        <projectRankingComparison
      :year="rankYear"
      :yearList="years"
      :show.sync="comparisonShow"
    ></projectRankingComparison>
  </div>
</template>

<script>
import { getCollegeDetail, doCollection, getMajorDetail, getcmsProgram } from 'api/libraryList.js';
import { mapActions } from 'vuex';
import projectRankingComparison from '../../homePage/components/projectRankingComparison.vue';
export default {
  name: 'BoxHead',
  components: {
    projectRankingComparison
  },
  props: ['schoolId', 'Id', 'isContrast'],
  watch: {
    schoolId(newVal, oldVal) {
      this.getCollegeDetailMethod(newVal);
    },
    Id(newVal, oldVal) {
        this.getproDetail(newVal);
      },
      isContrast(newVal, oldVal) {
        this.isContrast = oldVal;
      },
    },

  data() {
    return {
      dialogVisible: false,
      dataInfo: '',
      professionalInfo: {},
      comparisonShow: false,
      rankYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear().toString(),
      dataInfoPro: {},

    };
  },
  methods: {
    ...mapActions(['trackEvent']),
    toDetail() {
      this.trackEvent({ action: '学校详情页' });
      this.$router.push({ path: '/collageDetails', query: { id: this.schoolId }});
    },
    getCollegeDetailMethod(val) {
      getCollegeDetail({
        id: val
      }).then(res => {
        if (res.code == '2000') {
          this.dataInfo = res.data;
          console.log( this.dataInfo.collFalg );
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getMajorDetail(val) {
      getMajorDetail({
        id: val
      }).then(res => {
        if (res.code == '2000') {
          this.professionalInfo = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },

    getproDetail() {
      if ( this.$route.query.id) {
      getcmsProgram({
        id: this.$route.query.id
      }).then(res => {
        if (res.code == '2000') {
          this.dataInfoPro = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
    },

    onSearch() {},
    onQuery() {
      this.dialogVisible = true;
    },
    onCollect(val) {
      const params = {
        collectionId: this.Id ? this.Id : this.schoolId,
        collectionType: this.Id ? 1 : 0,
        isDelete: val.collFalg == true ? 1 : 0
      };

      doCollection(params).then(res => {
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: val.collFalg == true ? '取消收藏成功' : '收藏成功'
          });

          this.getCollegeDetailMethod(this.schoolId);
          if (this.Id) {
            this.getproDetail();
            }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    showComparison() {
      this.comparisonShow = true;
    }
  },
  mounted() {},
  computed: {
    years() {
      const startYear = 2022;
      const endYear = this.currentYear;
      const yearsArray = [];
      for (let year = startYear; year <= endYear; year++) {
        yearsArray.push({ name: year });
      }
      return yearsArray;
    }
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.professional-search {
  box-sizing: border-box;
  width: $width;
  background: #fff;
  border-radius: 12px 12px 12px 12px;
  margin-bottom: 20px;
  padding: 15px 20px 18px;
  align-items: unset;

  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .schoolInfo {
    height: 100%;
    flex: 1;
  }

  .query-btn {

  }

  .query-score {
    width: 165px;
    height: 30px;
    background: $themeColor;
    border-radius: 15px 15px 15px 15px;
    border: none;
    color: #fff;
    padding: 0;
  }

  .collect {
    width: 69px;
    height: 30px;
    background:#22C582;
    border-radius: 15px 15px 15px 15px;
    border: none;
    color: #fff;
    margin-left: 10px;
    padding: 0;
  }
  .contrast{
    width: 122px;
    height: 30px;
    background: $themeColor;
    border-radius: 15px 15px 15px 15px;
    border: none;
    color: #fff;
    margin-left: 10px;
    padding: 0;
  }
  .professional-name {
    font-weight: bold;
    font-size: 18px;
    padding: 0 10px 0 0;
  }
  .professional-tag p:nth-child(1) {
    margin: 0 0 0 10px;
    padding: 0 10px;
    height:24px;
    line-height: 24px;
    background: #FBB282;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
  }
  .professional-tag p:nth-child(2) {
    margin: 0 0 0 10px;
    padding: 0 10px;
    height:24px;
    line-height: 24px;
    background: #9EA5E0;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
  }
  .professional-tag p:nth-child(3) {
    margin: 0 0 0 10px;
    padding: 0 10px;
    height:24px;
    line-height: 24px;
    background: #9EE0D3;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
  }
  .professional-tag p:nth-child(4) {
    margin: 0 0 0 10px;
    padding: 0 10px;
    height:24px;
    line-height: 24px;
    background: #F5A8A8;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
  }
  .professional-tag p:nth-child(5) {
    margin: 0 0 0 10px;
    padding: 0 10px;
    height:24px;
    line-height: 24px;
    background: #9ea5e0;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
  }
  .professional-info {
    padding: 10px 0;
    line-height: 20px;
  }

  .professional-btn {
    color: $themeColor;
  }
}

.dialog {
  &>div:first-child {
    margin-bottom: 20px;
  }

  span {
    width: 100px;
    flex-shrink: 0;
    text-align: right;
  }
}

.dialog-footer {
  .okBtn {
    background: $themeColor;
    border-color: $themeColor;
  }

  :deep .el-button {
    width: 172px;
  }
}
.infoleft{
  margin-top: 10px;
}
</style>