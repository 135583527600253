<template>
    <div class="gift">
      <leftNav></leftNav>
      <div class="giftcontent">
          <div class="gift_top">
              <p class="title">学校申请信息表</p>
          </div>
          <div class="infoBox">
            <partone></partone>
          </div>
      </div>

     </div>
  </template>

  <script>
  import leftNav from "./leftNav";
  import Partone from "./components/Partone.vue";

  export default {
      components: {
          leftNav,
          Partone

      },
      data() {
          return {

          };
      },
      mounted() {

      },
      methods: {

      }
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';
  .gift{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color:#F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
  }
  .giftcontent{
      width 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .gift_top{
          padding:20px;
          border-bottom: 1px solid #EEE;
          .title{
              font-size: 20px;
              color: #333333;
              height:26px;
              position relative;
              padding-left: 14px;
              font-weight: bold;
          }
          .title::after{
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width :4px;
            height :20px;
            display: block;
            background-color: $themeColor;
            border-radius: 2px;
        }

      }

  }
  .infoBox{

   }
  </style>
  <style>
   #app{
     background-color: #F5F7FA;
    }
  </style>