<template>
  <div class="flex-justify-center">
    <div class="box">
      <div class="flex-center logoBox">
        <a @click="toHome"><img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/logo.png" alt=""/></a>
        <div class="tab-list finger">
          <div v-for="(item, index) in tabList" :key="index" @click="toPage(item.path, index)" :class="{activeCss:isActive(item.path)}">
            <p>{{ item.name }}</p>
            <span></span>
          </div>
        </div>
      </div>
      <div class="box_r flex-center">
        <p v-if="!token" class="loginText finger" @click="onLogin">登录</p>
        <div class="flex-center info" style="cursor: pointer;" v-else >
          <img :src="userInfo.headLogo" @click="topersonalCenter" alt="" style="border-radius: 50%;" />
          <p>用户：{{ userInfo.name }}</p>
          <div class="menuList">
            <span class="triangle-up"></span>
             <p @click="routerTo('/personalData')">个人资料</p>
             <p @click="routerTo('myCollection')">我的收藏</p>
             <p @click="routerTo('/myOrder')">我的订单</p>
             <!-- <p >我的档案</p> -->
             <p @click="loginOut">退出登录</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { api } from "@/api/api";
// import Cookies from 'js-cookie';
export default {
  name: '',
  data() {
    return {
      tabList: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '院校匹配',
          path: '/collegeMatching'
        },
        // {
        //   name: '案例分享',
        //   path: '/caseShare'
        // },
        {
          name: '超哥资讯',
          path: '/ininformational'
        },
        {
          name: 'Q&A',
          path: '/readQuestions'
        }
      ],
      tabIndex: 0,
      token: '',
      userInfo: {},
      isShow: false,
    };
  },
  created() {},

  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.token = localStorage.getItem('token') || '';
      this.userInfo = localStorage.getItem('userInfo');
    }
  },
  methods: {
    isActive(routePath) {
      return this.$route.path === routePath;
    },
    onLogin() {
      this.$store.dispatch('callSetIshow', true);
    },
    toPage(path, index) {
      this.toNext(path);
      this.tabIndex = index;
      // this.$store.dispatch('callSetVerifySendVerification', {
      //   self: this,
      //   fun: this.toNext,
      //   verifyArguments: path
      // });
    },
    toNext(val) {
      this.$router.push({
        path: val
      });
    },
    topersonalCenter() {
      this.$router.push({
        path: '/personalData'
      });
    },
    toHome() {
      this.$router.push({
        path: '/'
      });
    },
    routerTo(url) {
      this.$router
        .push({
          path: url
        })
        .then(() => {})
        .catch(() => {});
    },
    loginOut() {
      this.$confirm('确定退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
          this.$router.go(0);
        }).catch(() => {

        });
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';

.box {
  width: $width;
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
}

.el-message-box {
  width: 100% !important;
}

.logoBox {
  img {
    width: 74px;
    height: 28px;
  }

  .tab-list {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;

    &>div {
      color: #303243;
      margin-left: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }

    p {
      font-weight: 400;
      color: #303243;
    }

    span {
      width: 100%;
      display: inline-block;
      height: 3px;
      background: #fff;
      border-radius: 50px 50px 50px 50px;
      position: absolute;
      bottom: 0;
    }

    .activeCss {
      p {
        color: $themeColor;
        font-weight: bold;
      }

      span {
        background: $themeColor;
      }
    }
  }
}

.loginText {
  color: $themeColor;
}
.box_r{
  div {
    color: #666666
    img{
      width: 30px;
      height: 30px;
      margin-right: 10px
    }
  }
}
.info{
  position: relative;
}
.box_r:hover{
  .menuList{
    display: block;
  }
}
.menuList{
  position: absolute;
  top: 52px;
  left: -43px;
  width: 120px;
  padding:11px 0;
  text-align: center;
  background: #fff;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
  display: none;
  p{
    height: 45px;
    line-height: 45px;
    transition: all .3s;
    font-size: 14px;
    color: #666666;
  }
  p:hover{
    background: #FFEFE6;
    color: $themeColor
  }
  .triangle-up{
    width: 0;
    height: 0;
    border-left: 11.5px solid transparent; /* 左边框宽度约为底边宽度的一半 */
    border-right: 11.5px solid transparent; /* 右边框宽度也约为底边宽度的一半 */
    border-bottom: 12px solid #fff;
    position: absolute;
    top:-12px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>