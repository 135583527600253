import request from '@utils/request';
// 排名对比
// 获取学校列表
export function getSchoolRankingComparison(data) {
  return request({
    url: '/mv/cmsRank/universityRankCompare',
    method: 'post',
    data
  });
}

// 获取学院列表
export function getCollegeRankingComparison(data) {
  return request({
    url: '/mv/cmsRank/collegeRankCompare',
    method: 'post',
    data
  });
}

// 获取项目列表
export function getProjectRankingComparison(data) {
  return request({
    url: '/mv/cmsProgram/programWithMajorRanklist',
    method: 'post',
    data
  });
}
