export default {
  stick: {
    bind: (el, binding, vnode) => {
      let oldOffsetTop = null; // 保存当前元素的offsetTop，防止吸顶后offsetTop重置为0
      addEventListener('scroll', e => {
        const scrollTop = window.pageYoffset || document.documentElement.scrollTop || document.body.scrollTop;// 滚动距离
        const { offsetTop } = el; // 元素距离offsetParent的距离
        if (!oldOffsetTop) oldOffsetTop = offsetTop;
        if (scrollTop - oldOffsetTop > 0) {
          el.style.position = 'fixed';
          if (binding.value) {
            el.style.top = binding.value + 'px';
          } else {
            el.style.top = '0px';
          }
        } else {
          if (el.style.position === 'fixed') {
            el.style.position = 'static';
          }
        }
      });
    }
  }
};
