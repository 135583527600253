import request from '@utils/request';
// 接入微信接口
export const linkWechat = () => {
  return request({
    url: '/wechat/callback',
    method: 'get',
  });
};
// 生成微信扫描二维码
export const getWxUrl = () => {
  return request({
    url: '/wechat/wxUrl',
    method: 'get',
  });
};

// 微信扫码登录
export const toLogin = (code) => {
  return request({
    url: `/wechat/wechatLogin/${code}`,
    method: 'get',
  });
};