
import request from '@utils/request';

// 我收藏的学校
export function getmenuList(data) {
  return request({
    url: '/mv/cmsModule/getShowSwitchByCode',
    method: 'post',
    data
  });
}
// 我收藏的专业
export function getmenuDetail(data) {
    return request({
      url: '/mv/cmsModule/detail',
      method: 'post',
      data
    });
  }