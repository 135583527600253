<template>
  <div>
    <p class="title">第一部分：申请人详细情况</p>
    <div class="contentBox">
        <el-form :model="formData" inline class="flexs">
            <h3 >基本信息</h3>
            <el-row>
                <el-col :span="6">
                <el-form-item label="姓名：" label-width="90px">
                    <el-input v-model="formData.name"  placeholder="请输入" size="medium"  />
                </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item label="是否有曾用名（以户口本为准）：" >
                    <el-select v-model="formData.hasAlias" placeholder="请选择"  size="medium" >
                    <el-option label="是" value="yes" />
                    <el-option label="否" value="no" />
                    </el-select>
                </el-form-item>
                </el-col>
                <el-col :span="6">
                <el-form-item label="性别：" label-width="90px">
                    <el-select v-model="formData.gender" placeholder="请选择"  size="medium" >
                    <el-option label="男" value="male" />
                    <el-option label="女" value="female" />
                    </el-select>
                </el-form-item>
                </el-col>
            </el-row>
      </el-form>
  </div>
</div>
</template>

<script>
export default {
  name: "Partone",
  data() {
    return {
      countries: ["中国", "美国", "英国"],
      formData: {},
    };
  },
  mounted() {

  },
};
</script>

<style  lang='stylus' scoped>
@import '~css/stylus.styl';
.title{
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin:21px 0;
    text-align: center;
}
.flexs{
    .el-form-item{
        display: flex;
    }
}
.contentBox{
    width:900px;
    margin: 0 auto;
    h3{
        font-size: 16px;
        color: #333333;
        margin-bottom: 15px
    }
}
</style>