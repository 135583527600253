<template>
  <div class="questionbox">
    <div class="topban">
      <div class="topban_left">
        <p>
          <img src="~@/assets/share/iconleft.png" alt="" />Q&A<img
            src="~@/assets/share/iconright.png"
            alt=""
          />
        </p>
        <span>常见问题解决方法</span>
      </div>
    </div>
    <div class="questioncontent">
        <div class="contetn_tab">
            <p class="toggle" :class="{'active':index==activeIndexes}" @click="toggleChange(index)" v-for="(item,index) in toggleList" :key="index">
                <img :src="item.src" alt="">
                <img :src="item.src1" alt="">
                <span>{{ item.name }}</span>
            </p>
        </div>
        <p class="title" v-if="activeIndexes==0">关于系统</p>
        <div class="question">
            <el-collapse v-model="activeName" accordion v-if="activeIndexes==0 && this.questionList1.length>0">
                <el-collapse-item  v-for="(item,index) in questionList1" :key="`question-${index}`" :title="item.question" :name='`item-${index}`'>
                    <div class="content">{{ item.answer }}</div>
                </el-collapse-item>
            </el-collapse>
            <p class="title" v-if="activeIndexes==0  && this.questionList2.length>0" style="margin-top: 30px;">院校匹配</p>
            <el-collapse v-model="activeNames" accordion v-if="activeIndexes==0">
                <el-collapse-item  v-for="(item,index) in questionList2" :key="`question-${index}`" :title="item.question" :name='`item-${index}`'>
                    <div class="content">{{ item.answer }}</div>
                </el-collapse-item>
            </el-collapse>
            <div class="chatbox" v-else>
                <div
                    class="chat-messages"
                    v-for="(message, index) in messages"
                    :key="index"
                    :message="message">
                    <span>{{ message.time }}</span>
                    <p class="messagetxt" v-if="message.type==1">
                        <img src="../../assets/kefu.png" alt="">
                        <span>{{ message.content }}</span>
                    </p>
                    <p class="messagetxt flex-right" v-else-if="message.type==2">
                        <span>{{ message.content }}</span>
                        <img src="../../assets/defaultbigIcon.png" alt="">
                    </p>
                </div>
                <div class="message-input">
                    <el-input
                        v-model="messageInput"
                        type="text"
                        >
                        <el-button slot="append"  class="senbtn">发送</el-button>
                    </el-input>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { getQalist } from 'api/question.js';
export default {
    data() {
        return {
            activeName: "0",
            activeNames: "0",
            questionList1: [],
            questionList2: [],
            toggleList: [
                {
                    name: '系统问题',
                    value: '0',
                    src: require("@/assets/question/iconq1.png"),
                    src1: require("@/assets/question/iconq1_hover.png")
                },
                {
                    name: '业务咨询',
                    value: '1',
                    src: require("@/assets/question/iconq2.png"),
                    src1: require("@/assets/question/iconq2_hover.png")
                },
            ],
            messages: [
                {
                    type: "1",
                    content: "你好，请问有什么可以帮到你的吗？",
                    time: "2019-07-30 15:46",
                },
                {
                    type: "2",
                    content: "你好，请问有什么可以帮到你的吗？",
                    time: "2019-07-30 15:46",
                },
            ],
            activeIndexes: 0,
            messageInput: ''
        };
    },
    mounted() {
        this.getQuestion(1);
        this.getQuestion(2);
    },
    methods: {
        toggleChange(index) {
            this.activeIndexes = index === this.activeIndexes ? [] : [index];
        },
        getQuestion(types) {
            const data = {
                type: types
            };
            getQalist(data).then(res => {
                if (res.code == '2000') {
                    if (types === 1) {
                        this.questionList1 = res.data.list;
                    } else if (types === 2) {
                        // 假设还有另一个列表 questionList2 需要更新
                        this.questionList2 = res.data.list;
                    }
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                }
            });
        },
    }
};
</script>

<style lang="stylus" scoped >
@import '~css/stylus.styl';
.content{
    font-size: 14px;
    padding: 0 10px;
}
.questionbox{
    background: #F5F7FA;
    padding-bottom:84px;
}
.questioncontent{
    width: 1200px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    padding: 26px 30px;
    margin: -33px auto 0;
    position:relative
    z-index:2;
    min-height: 500px;

    .question{
        >>>.el-collapse-item{
            border-radius: 5px 5px 0px 0px;
        }
        >>>.el-collapse-item__header{
            background: #F5F7FA;
            padding: 15px 18px;
            height 19px;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
        }
        >>>.el-collapse-item__content{
            padding: 16px 15px;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            border-left: 1px solid #eee;
            border-right: 1px solid #eee;
        }
    }
}
.topban {
    width: 100%;
    height: 140px;
    background: url('~@/assets/question_ban.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index:1;
  }

  .topban_left {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
        height: auto;
        display: block;
        margin-right: 12px;
      }

      img:nth-of-type(2) {
        margin-left: 12px;
      }
    }

    span {
      display: block;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      height: 19px;
      margin-top: 6px;
    }
  }
  .contetn_tab{
    display: flex;
    justify-content:center;
    margin-bottom: 30px;
    .toggle{
        img{
            width: 15px;
            margin-right: 5px;
        }
        img:nth-of-type(2){
           display: none
        }
    }
    p{
        font-size: 16px;
        color: #666666;
        cursor: pointer;
        width 190px;
        height 40px;
        line-height 40px;
        border: 1px solid #EEEEEE;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    p.active{
        img:nth-of-type(1){
            display: none !important
        }
        img:nth-of-type(2){
            display: block !important
        }
    }
    p:nth-of-type(1){
        border-radius: 20px 0 0 20px;
        border-right: none;
    }
    p:nth-of-type(1).active{
        background: linear-gradient(-71deg, transparent 13px, #FFEFE6 0) top right;
        color: $themeColor;
     }
     p:nth-of-type(2){
        border-radius: 0 20px 20px 0;
        border-left: none;
        margin-left: -16px;
    }
    p:nth-of-type(2).active{
        background: linear-gradient(-70deg,#FFEFE6 177px, transparent  0) left top;
        color: $themeColor;
     }
  }
  .chatbox{
    background: #F8F8F8;
    border-radius: 12px 12px 12px 12px;
    padding-top: 20px;

    .chat-messages{
        padding: 0 29px;
        overflow-y: auto;
        >span{
            display: block;
            margin-bottom: 10px;
            text-align: center;
            font-size: 12px;
            color: #333333;
            height: 16px;
        }
        .flex-right{
            justify-content: flex-end;
            span{
                background: #EBF5FF !important;
                border-radius: 12px 0px 12px 12px !important;
            }
            img{
                margin-left: 15px;
            }
        }
        .messagetxt{
            display: flex;
            align-items: center;
            margin-bottom: 15px
            img{
                width: 45px;
                height 45px;
                border-radius: 50%;
                margin-right: 15px;
                display: block;
            }
            >span{
                display: block;
                width:496px;
                padding: 13px 20px;
                font-size: 14px;
                color: #333333;
                line-height: 30px;
                background: #FFFFFF;
                border-radius: 0px 12px 12px 12px;
            }
        }
    }
    .message-input{
        padding:15px 19px;
        margin: 60px auto 0;
        border-top: 1px solid #E1E1E1;
        >>>.el-input__inner{
            border-radius: 17px 0px 0 17px;
            height: 34px;
            line-height: 34px;
        }
        >>>.el-input-group__append{
            border:1px solid transparent
        }
        .senbtn{
            border-radius: 0px 17px 17px 0px;
            background: $themeColor;
            border: 1px solid transparent;
            color: #FFFFFF;
            height:34px
            line-height:34px;
            padding: 0 18px;
        }
    }
  }
  .title{
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    margin: 0 auto 20px;
    text-align: center;
  }
</style>