<template>
    <div class="gift">
      <leftNav></leftNav>
      <div class="giftcontent">
          <div class="gift_top">
              <p class="title">我的订单</p>
              <el-button type='text' style="padding: 0;" @click="back">返回上一页</el-button>
          </div>
          <div class="gift_bottom">
              <div class="infoBox">
                    <p>订单信息</p>
                    <div class="info">
                        <span>订单编号：{{ dataInfo.orderNo }}</span>
                        <span>订单名称：{{ dataInfo.orderName }}</span>
                        <span>订单金额：{{ dataInfo.orderAmount }}元</span>
                        <span>用户昵称：{{ dataInfo.orderUserName }}</span>
                        <span>身份：{{ dataInfo.identity }}</span>
                        <span>手机号：{{ dataInfo.orderUserMobile }}</span>
                        <span>下单时间：{{ dataInfo.createTime }}</span>
                    </div>
                    <p>订单内容</p>
                    <div class="ordContent">
                        <div v-if="dataInfo.gpaScoreFile!=null" class="excel">
                            <img src="../../assets/execl.png" alt="">
                            <p>GPA计算模板</p>
                            <el-button type="text" @click="downLoadFileClick(dataInfo.gpaScoreFile)">下载</el-button>
                        </div>
                        <div class="coursesbox" v-else>
                            <el-table :data="coursesList" border>
                                <el-table-column label="" prop="course" align="center">
                                    <template slot-scope="scope">
                                        课程{{ scope.$index + 1 }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="课程名称" prop="course" align="center"></el-table-column>
                                <el-table-column label="课程成绩" prop="score" align="center"></el-table-column>
                                <el-table-column label="课程学分" prop="credit" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <p>回复内容</p>
                    <div class="Reply">{{ dataInfo.replyContent || '暂无回复' }}</div>
              </div>
          </div>
      </div>

     </div>
  </template>

  <script>
  import leftNav from "./leftNav";
  import { Orderdetail } from "api/index.js";
  import { downloadFile } from '../../assets/js/index.js';
  export default {
      components: {
          leftNav,
      },
      data() {
          return {
              dataInfo: {},
              pageNum: 1,
              pageSize: 10,
              pageall: 0,
              coursesList: []
          };
      },
      mounted() {
        this.getOrder();
      },
      methods: {
          getOrder() {
            const data = {
                id: this.$route.query.id,
            };
            Orderdetail(data).then(res => {
              this.dataInfo = res.data;
              this.coursesList = JSON.parse( res.data.gpaScore);
            });
          },
          back() {
              this.$router.go(-1);
          },
          downLoadFileClick(url) {
            downloadFile(url)
                .then((res) => {
                console.log(res);
                })
                .catch((err) => {
                console.log(err);
                });
            },
      }
  };
  </script>

  <style  lang='stylus' scoped>
  .gift{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color:#F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
  }
  .giftcontent{
      width 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .gift_top{
          padding:20px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title{
              font-size: 20px;
              color: #333333;
              height:26px;
              position relative;
              padding-left: 14px;
              font-weight: bold;
          }
          .title::after{
              content: '';
              position: absolute;
              left: 0;
              top: 3px;
              width :4px;
              height :20px;
              display: block;
              background-color: $themeColor;
          }

      }
      .giftinput{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 27px auto 31px;
          .el-input{
              width: 330px;

          }
          >>>.el-input__inner{
              background-color: transparent;
              height:40px;
          }
          p{
              font-size: 14px;
              color: #666666;
          }
          .btn{
              width: 100px;
              height: 35px;
              background: #FF8240;
              border-radius: 21px 21px 21px 21px;
              font-size: 14px;
              color: #FFFFFF;
              margin-left: 10px;
              cursor: pointer;
              text-align: center;
              line-height: 35px;
          }
      }
  }
  .gift_bottom{
      padding: 0px 20px;
      p{
          font-size: 14px;
          color: #333333;
          margin-bottom: 15px;
          font-weight: bold;
      }
  }
  .infoBox{
    padding:22px 19px;
    p{
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        margin-bottom: 15px
    }
    .info{
        span{
            font-size: 14px;
            color: #333333;
            line-height: 26px;
            display: inline-block;
            margin-right: 50px;
            margin-bottom: 15px
        }
    }
    .Reply{
        background: #F8F8F8;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #D9DBDE;
        padding: 7px 10px;
        font-size: 14px;
        color: #999999;
        line-height: 24px;
    }
    .ordContent{
        margin-bottom: 20px
        .excel{
            display: flex;
            align-items: center;

            p{
                font-size: 14px;
                color: #333333;
                font-weight: 400;
                margin-right: 12px;
                margin-bottom: 0
            }
            img{
                width: 18px;
                height: 22px;
                margin-right: 11px
            }
        }
        .coursesbox{
            width:50%;
        }
    }
}
  </style>
  <style>
  #app{
    background-color: #F5F7FA;
}
</style>