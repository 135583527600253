import Vue from 'vue';
import Router from 'vue-router';
import routerFun from '@utils/routerFun.js';
// import {
//   component
// } from 'vue/types/umd';
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router);
const home = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/home');
const homePage = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/homePage/index');
const bannerDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/homePage/bannerDetail');
const libraryList = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/homePage/libraryList');
const universityRanking = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/homePage/universityRanking');
const collegeMatching = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/collegeMatching/index');
const professionalList = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/collegeMatching/professionalList');
const professionalDetails = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/collegeMatching/professionalDetails');
const collageDetails = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/collegeMatching/collageDetails');
const map = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/collegeMatching/map');
// 案例分享
const caseShare = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/caseSharing/caseShare');
const caseDetails = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/caseSharing/caseDetails');
// QS
const readQuestions = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/Question/readQuestions');
// 超哥资讯
const ininformational = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/Information/ininformational');
// 个人中心
const personalData = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/personalData');
const myGiftbag = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/myGiftbag');
const myCollection = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/myCollection');
const myOrder = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/myOrder');
const myOrderDetail = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/myOrderDetail');
const applicationForm = () => import( /* webpackChunkName: 'ImportFuncDemo' */ '@/views/personalCenter/applicationForm');
// const router = new Router({
//   mode: 'history',
const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    children: [
      {
        path: '/',
        name: 'homePage',
        component: homePage,
        meta: {
          title: '首页',
          isHome: true
        }
      },
      {
        path: '/bannerDetail',
        name: 'bannerDetail',
        component: bannerDetail,
        meta: {
          title: '推广'
        }
      },
      {
        path: '/universityRanking',
        name: 'universityRanking',
        component: universityRanking,
        meta: {
          title: '院校排名'
        }
      },
      {
        path: '/libraryList',
        name: 'libraryList',
        component: libraryList,
        meta: {
          title: '院校专业库'
        }
      },
      {
        path: '/collegeMatching',
        name: 'collegeMatching',
        component: collegeMatching,
        meta: {
          title: '院校匹配'
        }
      },
      {
        path: '/professionalList',
        name: 'professionalList',
        component: professionalList,
        meta: {
          title: '专业列表'
        }
      },
      {
        path: '/professionalDetails',
        name: 'professionalDetails',
        component: professionalDetails,
        meta: {
          title: '项目详情'
        }
      },
      {
        path: '/caseShare',
        name: 'caseShare',
        component: caseShare,
        meta: {
          title: '案例分享'
        }
      },
      {
        path: '/caseDetails',
        name: 'caseDetails',
        component: caseDetails,
        meta: {
          title: '案例详情'
        }
      },
      {
        path: '/readQuestions',
        name: 'readQuestions',
        component: readQuestions,
        meta: {
          title: 'Q&A'
        }
      },
      {
        path: '/ininformational',
        name: 'ininformational',
        component: ininformational,
        meta: {
          title: '超哥资讯'
        }
      },
      {
        path: '/personalData',
        name: 'personalData',
        component: personalData,
        meta: {
          title: '个人资料'
        }
      },
      {
        path: '/myGiftbag',
        name: 'myGiftbag',
        component: myGiftbag,
        meta: {
          title: '我的礼包'
        }
      },
      {
        path: '/myCollection',
        name: 'myCollection',
        component: myCollection,
        meta: {
          title: '我的收藏'
        }
      },
      {
        path: '/collageDetails',
        name: 'collageDetails',
        component: collageDetails,
        meta: {
          title: '专业列表'
        }
      },
      {
        path: '/myOrder',
        name: 'myOrder',
        component: myOrder,
        meta: {
          title: '我的订单'
        }
      },
      {
        path: '/myOrderDetail',
        name: 'myOrderDetail',
        component: myOrderDetail,
        meta: {
          title: '订单详情'
        }
      },
      {
        path: '/applicationForm',
        name: 'applicationForm',
        component: applicationForm,
        meta: {
          title: '学校申请信息表'
        }
      },
      {
        path: '/map',
        name: 'map',
        component: map,
        meta: {
          title: '地图详情'
        }
      },
    ]
  }
];
// });

// 全局路由守卫
// router.beforeEach(function (to, from, next) {
//   if (sessionStorage.getItem('token')) { // 判断是否登录
//     next();
//   } else { // 没登录则跳转到登录界面
//     if (to.path === '/') {
//       next();
//     } else {
//       // next({
//       //   path: '/'
//       // });
//       next();
//     }
//   }
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
// });

export default routerFun(Router, routes);
