<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox">
      <p class="title">基础信息<span class="editClass" @click="editInfo">编辑资料</span></p>
      <div class="infopng">
        <p>
          <!-- <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            >
            <img v-if="form.headLogo" :src="form.headLogo" class="avatar" style="border-radius: 50%;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span class="btn">更换头像</span>
          </el-upload> -->
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="uploadSectionFile" accept=".jpg,.jpeg" :limit="1" :multiple="false" :on-remove="handleRemove" :file-list="form.imageUrl ? [{ name: '用户头像', url: form.imageUrl }] : []">
            <img v-if="form.headLogo" :src="form.headLogo" class="avatar"  />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span class="btn" v-if="!isEdit">更换头像</span>
          </el-upload>
        </p>
      </div>
      <div class="info">
        <el-form :model="form" :rules="rules" label-width="90px" label-position="left">
          <el-form-item  label="昵称：">
            <el-input v-model="form.name" placeholder="请输入昵称" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="我的身份：">
            <el-select v-model="form.userType" placeholder="请选择身份" :disabled="isEdit">
              <el-option
                v-for="item in identityList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="我的学校：">
            <el-select
            :disabled="isEdit"
            style="display:block"
            v-model="form.schoolName"
            filterable
            remote
            :remote-method="remoteSearch"
            placeholder="请输入关键词"
            >
            <el-option
              v-for="item in collegeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
            <!-- <el-input v-model="form.schoolName" placeholder="请输入我的学校"></el-input> -->
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="form.phone"  placeholder="请输入手机号" :disabled="isEdit"></el-input>
          </el-form-item>
        </el-form>
        <p class="sendBtn" @click="Save" v-if="!isEdit">保存</p>
      </div>
    </div>
  </div>
</template>

<script>
import leftNav from "./leftNav";
import { uploadFile } from 'api/index.js';
import { cmsUserUpdate } from 'api/login.js';
import { getBkSchool } from 'api/caseSharing.js';
export default {
  components: {
    leftNav,
  },
  data() {
    const mobileRule = (rule, value, callback) => {
      const regExp = /^1[345789]\d{9}$/;
      if (regExp.test(value) === false) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      imageUrl: require('../../assets/defaultbigIcon.png'),
      form: {},
      identityList: [
        {
          value: 0,
          label: "学生",
        },
        {
          value: 1,
          label: "学生家长",
        },
        // {
        //   value: 2,
        //   label: "销售",
        // },
      ],
      rules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, message: '手机号码长度有误', trigger: 'blur' },
          { validator: mobileRule, trigger: ['blur', 'change'] }
        ]
      },
      collegeList: [],
      isEdit: true
    };
  },
  methods: {

    handleAvatarSuccess(res, file) {
      this.form.headLogo = URL.createObjectURL(file.raw);
    },
    handleRemove(file, fileList) {
      this.$set(this.form, 'imageUrl', '');
    },
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      console.log(isJPG, isPNG);
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '上传成功'
          });
          this.$set(this.form, 'headLogo', res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    Save() {
      cmsUserUpdate(this.form).then(res => {
            if (res.code == 2000) {
              this.$message({
                type: 'success',
                message: res.msg
              });
              localStorage.setItem('userInfo', this.form);
              this.isEdit = true;
              window.location.reload();
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.collegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    },
    editInfo() {
      this.isEdit = !this.isEdit;
    }
  },
  mounted() {
    this.form = localStorage.getItem('userInfo');
  }
};
</script>

<style  lang='stylus' scoped>
@import '~css/stylus.styl';
.infobigBox{
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color:#F5F7FA;
    padding-bottom: 60px;
    margin: 0 auto;
}
.infoBox{
  width 940px;
  background-color: #fff;
  margin: 27px 2% 27px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
  padding: 23px 20px;
  .title{
      font-size: 20px;
      color: #333333;
      height:23px;
      position relative;
      padding-left: 14px;
  }
  .title::after{
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width :4px;
      height :20px;
      display: block;
      background-color: $themeColor;
      border-radius: 2px;
  }
  .infopng{
    display: flex;
    margin: 30px 0 33px;
    align-items: center;
    p{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      .avatar-uploader{
        position:relative
      }
      .avatar-uploader .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .btn{
          width: 92px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 15px 15px 15px 15px;
          border: 1px solid $themeColor;
          font-size: 14px;
          color: $themeColor;
          text-align: center;
          line-height: 30px;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 102px;

        }
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }
      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }

    }
  }
}
.info{
  width: 470px;
  .el-select{
    display: block;
  }
  .sendBtn{
    width: 250px;
    height: 50px;
    background: $themeColor;
    border-radius: 90px 90px 90px 90px;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 50px
    text-align: center;
    margin-top: 34px;
    cursor:pointer
  }
}
.avatar{
  border-radius: 50%
}
.editClass{
  width: 92px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  border: 1px solid #FF8240;
  font-weight: 400;
  font-size: 14px;
  color: #FF8240;
  text-align: center;
  line-height: 26px;
  float: right;
  cursor pointer;
}
</style>
<style>
#app{
    background-color: #F5F7FA;
}
</style>