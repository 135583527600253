<template>
  <div class="caseBox">
    <div class="topban">
      <headerTitle title="案例分享" :translate="true" :titles="['案例详情']" :showBtn="true"></headerTitle>
    </div>
    <div class="screening">
      <p class="caseBox-title">筛选</p>
      <div class="condition">
        <el-radio-group v-model="searchInfo.caseType" style="margin-right: 44px" @change="changeColl">
          <el-radio-button v-for="(item, index) in isCollapseList" :key="index" :label="item.value">{{ item.name }}</el-radio-button>
        </el-radio-group>
        <el-form inline>
          <el-form-item label="年份筛选:" :label-width="formLabelWidth">
            <el-date-picker @change="changeColl" format="yyyy" value-format="yyyy" :picker-options="setDateRange" v-model="searchInfo.year" type="year" placeholder="请选择"></el-date-picker>
          </el-form-item>
          <el-form-item label="院校等级:" :label-width="formLabelWidth">
            <el-select clearable v-model="searchInfo.levelId" placeholder="请选择" @change="changeColl">
              <el-option v-for="item in LEVELList" :key="item.code" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bigcontent">
      <div class="caseBox-content">
        <div class="case" v-for="(item, index) in caseList" :key="index" @click="showOffer(item)">
          <p>申请季：{{ item.applyTime }}</p>
          <span>目标院校：{{ item.targetSchoolName }}</span>
          <span>专业名称：{{ item.targetCurriculumName }}</span>
          <span>背景院校：{{ item.schoolName }}</span>
          <span>院校等级：{{ item.level }}</span>
          <span>均分：{{item.score }}</span>
          <div class="case-bottom" v-if="item.caseType == 0">
            <img src="@/assets/share/pass.png" alt="" />
          </div>
          <div class="case-bottom" v-else>
            <img src="@/assets/share/fail.png" alt="" />
          </div>
        </div>
      </div>
      <el-empty v-if="caseList.length == 0" description="暂无数据"></el-empty>
      <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick" style="margin-top: 20px"></paging>
    </div>
    <el-dialog :visible.sync="dialogFormVisible" :show-close="false" width="504px">
      <div class="information" ref="screenshotArea">
        <div class="top_ban">
          <img src="@/assets/share/sharelogo.png" alt="" />
          <span>专注申请，专心为你</span>
        </div>
        <div class="top_title">
          <p>{{ dialogForm.targetSchoolName }}</p>
          <div v-if="dialogForm.caseType == 0">
            <img src="@/assets/share/pass.png" alt="" />
          </div>
          <div v-else><img src="@/assets/share/fail.png" alt="" /></div>
        </div>
        <div class="top_sub">
          <p>申请季：{{ dialogForm.applyTime }}</p>
          <p>专业名称：{{ dialogForm.targetCurriculumName }}</p>
        </div>
        <div class="info_text">
          <p>地区：{{ dialogForm.area }}</p>
          <p v-if="dialogForm.resultsTime">获得结果时间：{{ dialogForm.resultsTime }}</p>
          <p>院校名：{{ dialogForm.schoolName }}</p>
          <p>专业：{{ dialogForm.majorName }}</p>
          <p>英语成绩：{{ dialogForm.scoreEn }}</p>
          <p v-if="dialogForm.time">录取时间：{{dialogForm.time }}</p>
          <p>奖学金：{{ dialogForm.scholarship }}</p>
          <p>最高学历：{{ dialogForm.highestEducationLevel }}</p>
          <p>院校等级：{{ dialogForm.level }}</p>
          <p>成绩：{{ dialogForm.score }}</p>
          <p>工作经历：{{ dialogForm.workExperience || '无' }}</p>
          <p>备注：{{ dialogForm.remark || '/' }}</p>
          <!-- <p class="Qrcode"></p> -->
        </div>
      </div>
      <div slot="footer" id="removeMe" class="dialog-footer">
        <el-button round @click="dialogFormVisible = false">返回</el-button>
        <el-button round @click="sharePic">生成图片并下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import html2canvas from 'html2canvas';
import paging from '@/components/paging.vue';
import { getCaseInfoList } from 'api/caseSharing.js';
import { dicListByCode } from 'api/index.js';

export default {
  components: {
    headerTitle,
    paging
  },
  data() {
    return {
      setDateRange: {
        disabledDate: time => {
          return time.getFullYear() > new Date().getFullYear();
        }
      },
      searchInfo: {
        caseType: '',
        year: '',
        levelId: ''
      },
      isCollapseList: [
        {
          name: '全部',
          value: ''
        },
        {
          name: '成功案例',
          value: '0'
        },
        {
          name: '失败案例',
          value: '1'
        }
      ],
      dicCodelist: ['LEVEL'],
      LEVELList: [],
      formLabelWidth: '80px',
      caseList: [],
      dialogFormVisible: false,
      dialogForm: {},
      pageall: 0,
      pagenum: 1,
      datas: {}
    };
  },
  mounted() {
    this.datas = this.$route.query.datas;
    if (this.datas) {
      this.searchInfo.caseType = this.datas.caseType;
      this.searchInfo.levelId = this.datas.levelId ? this.datas.levelId.toString() : '';
      console.log(this.searchInfo, 'this.searchInfo');
      this.searchInfo.year = this.datas.year || '';
    }
    console.log(this.datas, 'this.datas');
    this.getCodeList();
    this.getList();
  },
  methods: {
    getList() {
      const data = {
        ifPage: true,
        pageSize: 12,
        pageNum: this.pagenum,
        ...this.datas,
        levelId: this.searchInfo.levelId || '',
        year: this.searchInfo.year || '',
        caseType: this.searchInfo.caseType,
      };

      getCaseInfoList(data).then(res => {
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.caseList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getCodeList() {
      this.dicCodelist.forEach(item => {
        dicListByCode({ code: item })
          .then(res => {
            if (res.code == '2000') {
              this[item + 'List'].unshift(...res.data);
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            this.$message({
              type: 'error',
              message: '请求过程中发生错误，请稍后再试。'
            });
          });
      });
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    changeColl() {
      this.pageclick(1);
    },
    showOffer(item) {
      this.dialogFormVisible = true;
      this.dialogForm = item;
    },
    async sharePic() {
      // 隐藏要移除的元素
      // const elementToRemove = document.getElementById('removeMe');
      // elementToRemove.style.display = 'none';
      try {
        const canvas = await html2canvas(this.$refs.screenshotArea);
        const imgUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgUrl;
        link.download = 'screenshot.png';
        link.click();
        // elementToRemove.style.display = 'block';
      } catch (error) {
        console.error('html2canvas error:', error);
        // 出错时，也确保元素重新显示
        // elementToRemove.style.display = 'block';
      }
    }
  }
};
</script>

<style lang="stylus" scoped >
@import '~css/stylus.styl';
.caseBox {
  background-color: #f5f7fa;
  padding-bottom: 84px;
}

.bigcontent {
  width: 1200px;
  padding: 20px 21px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  margin: 0 auto;

  .caseBox-content {
    .case {
      width: calc(((100% - 40px) / 3));
      padding: 20px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;
      float: left;
      margin-right: 20px;
      box-sizing: border-box;
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      border-radius: 8px 8px 8px 8px;
      transition: box-shadow 0.3s ease;

      p {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 32px;
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
        color: #333333;
        height: 19px;
        display: block;
        margin-bottom: 9px;
      }

      span:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      .case-bottom {
        position: absolute;
        right: 20px;
        bottom: 20px;

        img {
          width: 100px;
          height: auto;
          display: block;
        }
      }

      .fail {
        color: #f56c6c;
        border-color: #f56c6c;
      }
    }

    .case:hover {
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
      >p:nth-of-of-type(1) {
        color: #FF8240;
      }
    }

    .case:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.caseBox-content::after {
  content: '';
  display: block;
  clear: both;
}

.topban {
  width: 100%;
  height: 140px;
  background: url('~@/assets/share/share_banner.png') no-repeat;
  background-size: 100% 100%;
}

.screening {
  width: 1200px;
  padding: 20px 22px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
  margin: -61px auto 20px;
  background-color: #fff;

  .caseBox-title {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    padding-left: 14px;
    position: relative;
    margin-bottom: 15px;
  }

  .caseBox-title::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 20px;
    background: $themeColor;
    border-radius: 90px 90px 90px 90px;
    display: block;
  }

  .condition {
    display: flex;
  }

  .el-form {
    .el-form-item {
      margin-bottom: 0;
      margin-right: 44px;

      >>>.el-input__inner {
        width: 210px;
        height: 35px;
        line-height: 35px;
      }
    }
  }

  >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #FF8240;
    border-color: #FF8240;
    color: #fff;
  }

  >>>.el-radio-button__inner:hover {
    color: #FF8240;
  }

  >>>.el-radio-button__inner {
    padding: 10.38px 20px;
  }
}

>>>.el-dialog {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0) !important;
}

>>>.el-dialog__header {
  padding: 0;
}

>>>.el-dialog__body {
  padding: 0;
}

.information {
  width: 100%;
  padding: 47px 15px 24px;
  background: url('~@/assets/share/shareban.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  min-height: 821px;

  .top_ban {
    margin-top: 21px;
    margin-bottom: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 23px;
    box-sizeing: border-box;

    img {
      width: 105px;
      height: auto;
      display: block;
    }

    span {
      font-size: 16px;
      color: #333333;
      line-height: 0px;
    }
  }

  .top_title {
    padding: 20px 27px 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    p {
      font-weight: bold;
      font-size: 40px;
      color: #333333;
      line-height: 48px;
    }

    div {
      img {
        width: 110px;
        height: auto;
        display: block;
      }
    }
  }
}

.top_sub {
  padding: 5px 27px 16px 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: bold;
    font-size: 16px;
    color: #FF8240;
    line-height: 26px;
  }
}

.info_text {
  padding: 20px 20px;
  box-sizing: border-box;
  background: linear-gradient( 180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
  margin: 0 auto;
  width:91%;
  p {
    font-size: 14px;
    color: #333333;
    height: 19px;
    margin-bottom: 10px;
  }

  .Qrcode {
    width: 95px;
    height: 95px;
    background-color: #ccc;
    margin: 34px auto 0;
  }
}

>>>.el-dialog__footer {
  padding: 20px 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;

  .el-button {
    width: 48%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding: 0;
    border-radius: 25px;
    border-color: #fff
  }

  .el-button:nth-child(1) {
    background: rgba(255, 255, 255, 0);
    border: 1px solid #FFFFFF;
    color: #fff;
  }
}

>>>.el-dialog {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

>>>.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>