export default {
  state: {
    isShow: false,
    self: '',
    verifySendVerification: null,
    verifyArguments: null,
  },
  mutations: {
    setIshow(state, isShow) {
      state.isShow = isShow;
    },
    setVerifySendVerification(state, obj) {
      state.self = obj.self;
      state.verifySendVerification = obj.fun;
      state.verifyArguments = obj.verifyArguments;
      if (localStorage.getItem('token')) {
        if (state.verifyArguments) {
          state.verifySendVerification.call(state.self, state.verifyArguments);
        } else {
          state.verifySendVerification.call(state.self);
        }
      } else {
        state.isShow = true;
      }
    },
    // 验证后跳转
    VerifyTo(state) {
      if (state.verifyArguments) {
        state.verifySendVerification.call(state.self, state.verifyArguments);
      } else {
        state.verifySendVerification.call(state.self);
      }
      state.isShow = false;
    }
  },
  actions: {
    callSetIshow({
      commit
    }, isShow) {
      commit('setIshow', isShow);
    },
    callSetVerifySendVerification({
      commit
    }, obj) {
      commit('setVerifySendVerification', obj);
    },
    callVerifyTo({
      commit
    }) {
      commit('VerifyTo');
    }
  }
};