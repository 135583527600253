<template>
  <div id="app">
    <verification-dialog v-if="$store.state.verification.isShow"></verification-dialog>
    <keep-alive>
      <router-view v-if="$route.meta.keepalive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepalive" />
  </div>
</template>
<script>
import VerificationDialog from '@/components/VerificationDialog.vue';
import logMethod from '@utils/logMethod.js';
export default {
  name: 'App',
  components: {
    VerificationDialog
  },
  beforeDestroy() {
    // 关闭indexedDB数据库连接
    logMethod.closeDb();
  }
};
</script>

<style lang="stylus">
body, html, #app {
  width: 100%;
  height: 100%;
  color: #333333;
  font-size: 14px;
}
</style>
