<template>
  <div class="collegeBox">
    <div class="topban">
      <div class="topban_left">
        <headerTitle title="首页" :titles="['院校匹配', '学校详情']" :showBtn="true" :translate="true"></headerTitle>
      </div>
    </div>
    <div class="topBox">
      <img class="img" :src="dataInfo.logo" alt="">
      <p class="collect"  @click="onCollect(dataInfo)">收藏
        <i style="color: #fff" class="el-icon-star-on" v-if="dataInfo.collFalg==true"></i>
        <i style="color: #fff" class="el-icon-star-off" v-else></i>
      </p>
      <div class="topinfo">
        <p class="infotxt">
          <font @click="toLink(dataInfo.homepage)">{{dataInfo.universityNameCn}} | {{ dataInfo.universityNameEn }}</font>
          <span>{{dataInfo.area}}</span>
          <!-- <span>专业均分要求：{{dataInfo.score}}分</span> -->
          <span>QS排名：{{dataInfo.qsRank}}</span>
          <span>U.S.News排名：{{dataInfo.usnewsRank}}</span>
          <span>The排名：{{dataInfo.theRank}}</span>
          <span>Rank排名：{{dataInfo.ruankeRank}}</span>
        </p>
        <p class="introduce">
          <span>地址：{{ dataInfo.addressCn  }} |  {{ dataInfo.address }}</span>
          <span v-if="dataInfo.foundedIn">建校年份：{{ dataInfo.foundedIn }}年</span>
          <span v-if="dataInfo.attributes">学校性质：{{ dataInfo.attributes }}</span>
          <span v-if="dataInfo.internationalPercentage">留学生比例：{{ dataInfo.internationalPercentage }}</span>
        </p>
      </div>
    </div>
    <div class="locaBox">
      <p class="title">学校简介</p>
      <div class="introBox">
        <p>
          <span v-if=" dataInfo.city">城市：{{ dataInfo.city }}</span>
          <span v-if=" dataInfo.state">州 (英文)：{{ dataInfo.state }}</span>
          <span v-if=" dataInfo.zipcode">邮编 (英文){{ dataInfo.zipcode }}</span>
          <span v-if=" dataInfo.country">国家 (2位英文码)：{{ dataInfo.country }}</span>
          <span v-if=" dataInfo.phone">通用电话：{{ dataInfo.phone }}</span>
          <span v-if=" dataInfo.email">通用邮箱：{{ dataInfo.email }}</span>
        </p>
        <p>简介（中文）：{{ dataInfo.introduction }}</p>
        <p>简介（英文）：{{ dataInfo.introductionEn }}</p>
      </div>
      <p class="title">学校扩展信息</p>
      <div class="extendedBox">
        <div>
        <p v-if="dataInfo.reputation">学校整体声誉描述（中文）：{{ dataInfo.reputation }}
          <span v-if="dataInfo.linkReputation">学校整体声誉描述链接：<font @click="toLink(dataInfo.linkReputation)">{{ dataInfo.linkReputation }}</font></span>
        </p>
        <p v-if="dataInfo.reputationEn">学校整体声誉描述（英文）:{{ dataInfo.reputationEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.linkAcademicPrograms" @click="toLink(dataInfo.linkAcademicPrograms)">学校学术项目（中文）:{{ dataInfo.linkAcademicPrograms }}
          <span v-if="dataInfo.linkAcademicPrograms">学校学术项目链接：<font>{{ dataInfo.linkAcademicPrograms }}</font></span>
        </p>
        <p v-if="dataInfo.academicProgramsEn">学校学术项目（英文）:{{ dataInfo.academicProgramsEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.faculty">学校师资介绍（中文）：{{ dataInfo.faculty }}
          <span v-if="dataInfo.linkFaculty">学校师资介绍链接：<font @click="toLink(dataInfo.linkFaculty)">{{ dataInfo.linkFaculty }}</font></span>
        </p>
        <p v-if="dataInfo.facultyEn">学校师资介绍（英文）:{{ dataInfo.facultyEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.research">学校研究（中文）：{{ dataInfo.research }}
          <span v-if="dataInfo.linkResearch">学校研究链接：<font @click="toLink(dataInfo.linkResearch)">{{ dataInfo.linkResearch }}</font></span>
        </p>
        <p v-if="dataInfo.researchEn">学校研究（英文）：{{ dataInfo.researchEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.diversityEn">学校多样性（中文）：{{ dataInfo.diversityEn }}
          <span v-if="dataInfo.linkDiversity">学校多样性链接：<font @click="toLink(dataInfo.linkDiversity)">{{ dataInfo.linkDiversity }}</font></span>
        </p>
        <p v-if="dataInfo.diversity">学校多样性（英文）：{{ dataInfo.diversity }}</p>
      </div>

      <div>
        <p v-if="dataInfo.careerStatistics">学校整体就业数据概况（中文）:{{ dataInfo.careerStatistics }}
          <span v-if="dataInfo.linkCareerStatistics">学校整体就业数据概况链接：<font @click="toLink(dataInfo.linkCareerStatistics)">{{ dataInfo.linkCareerStatistics }}</font></span>
        </p>
        <p v-if="dataInfo.careerStatisticsEn">学校整体就业数据概况（英文）：{{ dataInfo.careerStatisticsEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.financialAid">学校奖学金资助概况（中文）:{{ dataInfo.financialAid }}
          <span v-if="dataInfo.linkFinancialAid">学校奖学金资助概况链接：<font @click="toLink(dataInfo.linkFinancialAid)">{{ dataInfo.linkFinancialAid }}</font></span>
        </p>
        <p v-if="dataInfo.financialAidEn">学校奖学金资助概况（英文）：{{ dataInfo.financialAidEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.livingCost">学校所在城市区域生活成本概况（中文）:{{ dataInfo.livingCost }}
          <span v-if="dataInfo.linkLivingCost">学校所在城市区域生活成本概况链接：<font @click="toLink(dataInfo.linkLivingCost)">{{ dataInfo.linkLivingCost }}</font></span>
        </p>
        <p v-if="dataInfo.livingCostEn">学校所在城市区域生活成本概况（英文）：{{ dataInfo.livingCostEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.geographic">学校所在城市区域地理位置概况（中文）：{{ dataInfo.geographic }}
          <span v-if="dataInfo.linkGeographic">学校所在城市区域地理位置概况链接：<font @click="toLink(dataInfo.linkGeographic)">{{ dataInfo.linkGeographic }}</font></span>
        </p>
        <p v-if="dataInfo.geographicEn">学校所在城市区域地理位置概况（英文）：{{ dataInfo.geographicEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.mentoring">学校对学生整体指导方面的（中文）:{{ dataInfo.mentoring }}
          <span v-if="dataInfo.linkMentoring">学校对学生整体指导方面的链接：<font @click="toLink(dataInfo.linkMentoring)">{{ dataInfo.linkMentoring }}</font></span>
        </p>
        <p v-if="dataInfo.mentoringEn">学校对学生整体指导方面的（英文）：{{ dataInfo.mentoringEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.healthWellbeing">学校对支持学生健康医疗方面概况（中文）:{{ dataInfo.healthWellbeing }}
          <span v-if="dataInfo.linkHealthWellbeing">学校对支持学生健康医疗方面概况链接：<font @click="toLink(dataInfo.linkHealthWellbeing)">{{ dataInfo.linkHealthWellbeing }}</font></span>
        </p>
        <p v-if="dataInfo.healthWellbeingEn">学校对支持学生健康医疗方面概况（英文）：{{ dataInfo.healthWellbeingEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.professionalDevelopment">学校职业规划方面的（中文）:{{ dataInfo.professionalDevelopment }}
          <span v-if="dataInfo.linkProfessionalDevelopment">学校职业规划方面的链接：<font @click="toLink(dataInfo.linkProfessionalDevelopment)">{{ dataInfo.linkProfessionalDevelopment }}</font></span>
        </p>
        <p v-if="dataInfo.professionalDevelopmentEn">学校职业规划方面的（英文）：{{ dataInfo.professionalDevelopmentEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.internationalStudentEnglish">学校对国际学生英文方面的支持（中文）:{{ dataInfo.internationalStudentEnglish }}
          <span v-if="dataInfo.linkInternationalStudentEnglish">学校对国际学生英文方面的支持链接：<font @click="toLink(dataInfo.linkInternationalStudentEnglish)">{{ dataInfo.linkInternationalStudentEnglish }}</font></span>
        </p>
        <p v-if="dataInfo.internationalStudentEnglishEn">学校对国际学生英文方面的支持（英文）：{{ dataInfo.internationalStudentEnglishEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.childcareResources">学校对学生子女 (如果是父母)的帮助（中文）:{{ dataInfo.childcareResources }}
          <span v-if="dataInfo.linkChildcareResources">学校对学生子女 (如果是父母)的帮助链接：<font @click="toLink(dataInfo.linkChildcareResources)">{{ dataInfo.linkChildcareResources }}</font></span>
        </p>
        <p v-if="dataInfo.childcareResourcesEn">学校对学生子女 (如果是父母)的帮助（英文）：{{ dataInfo.childcareResourcesEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.studentOrganizations">学校学生团体方面的（中文）:{{ dataInfo.studentOrganizations }}
          <span v-if="dataInfo.linkStudentOrganizations">学校学生团体方面的链接：<font @click="toLink(dataInfo.linkStudentOrganizations)">{{ dataInfo.linkStudentOrganizations }}</font></span>
        </p>
        <p v-if="dataInfo.studentOrganizationsEn">学校学生团体方面的（英文）：{{ dataInfo.studentOrganizationsEn }}</p>
      </div>

      <div>
        <p v-if="dataInfo.communicationWithSchool">获取学校最新信息方面的（中文）:{{ dataInfo.communicationWithSchool }}
          <span v-if="dataInfo.linkCommunicationWithSchool">获取学校最新信息方面的链接：<font @click="toLink(dataInfo.linkCommunicationWithSchool)">{{ dataInfo.linkCommunicationWithSchool }}</font></span>
        </p>
        <p v-if="dataInfo.communicationWithSchoolEn">获取学校最新信息方面的（英文）：{{ dataInfo.communicationWithSchoolEn }}</p>
      </div>

    </div>
      <p class="title">地理位置</p>
      <div class="mapContent">
        <div class="map" id="allmap"></div>
        <div class="map">
          <baidu-map id="maps" class="bm-view" :center="center"></baidu-map>
        </div>
        <div class="mapTxt">
          <p>地址：{{ dataInfo.addressCn }}</p>
          <p>Address：{{ dataInfo.address }}</p>

        </div>
      </div>
      <p class="title" v-if="dataInfo.climate">气候</p>
      <div class="content">
        <p @click="toLink(dataInfo.climateLink)">{{ dataInfo.climate }}</p>
      </div>
      <p class="title"  v-if="dataInfo.safetyIndex">安全指数</p>
      <div class="content" v-if="dataInfo.safetyIndex">
        <p  @click="toLink(dataInfo.safetyIndexLink)">{{dataInfo.safetyIndex}}</p>
        <!-- <el-rate v-model="dataInfo.safetyIndex" disabled text-color="#ff9900"></el-rate>
        <p style="display: inline-block;margin-left: 11px;" @click="toLink(dataInfo.safetyIndexLink)">{{ dataInfo.safetyIndex }}星</p> -->
      </div>
      <p class="title"  v-if="dataInfo.cost">生活成本</p>
      <div class="content">
        <p  @click="toLink(dataInfo.costLink)">{{ dataInfo.cost }}</p>
      </div>
    </div>
  </div>
</template>
<script src="//api.map.baidu.com/api?type=webgl&v=1.0&ak=CxN6aJlgWO3u4eEqnphOuzy0Z0fjgcOJ"></script>
<script>
import headerTitle from "@/components/title.vue";
import { getCollegeDetail, doCollection } from "api/libraryList.js";
export default {
  components: {
    headerTitle,
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      dataInfo: {},
    };
  },
  mounted() {
    this.getCollegeDetailMethod();
  },
  methods: {
    getCollegeDetailMethod() {
      getCollegeDetail({
        id: this.$route.query.id,
      }).then((res) => {
        if (res.code == "2000") {
          this.dataInfo = res.data;
          this.dataInfo.safetyIndex = Number(this.dataInfo.safetyIndex);
          this.initMapEarth();
          this.initMap();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    initMap() {
      var map = new BMapGL.Map("maps");
      map.enableScrollWheelZoom(true);
      const point = new BMapGL.Point(this.dataInfo.lng, this.dataInfo.lat);
      map.centerAndZoom(point, 13);
      var marker = new BMapGL.Marker(point);
      // 将信息窗口的配置独立出来，提高可读性
      var sContent = `<p style='margin:0 0 5px 0;'>${this.dataInfo.universityNameCn}</p>`;
      // 使用常量代替硬编码的文本
      const infoWindow = new BMapGL.InfoWindow(sContent);
      map.openInfoWindow(infoWindow, point);
    },
    initMapEarth() {
      // 创建Map实例
      var map = new BMapGL.Map("allmap"); // 创建Map实例
      map.centerAndZoom(
        new BMapGL.Point(this.dataInfo.lng, this.dataInfo.lat),
        3
      ); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      map.setMapType(BMAP_EARTH_MAP);
      var point = new BMapGL.Point(this.dataInfo.lng, this.dataInfo.lat);
      // 地图上创建marker点
      var marker = new BMapGL.Marker(point);
      // 地图上添加点
      map.addOverlay(marker);
    },
    toLink(url) {
      window.open(url, "_blank");
    },
    onCollect(val) {
      const params = {
        collectionId: val.id,
        collectionType: 0,
        isDelete: val.collFalg == true ? 1 : 0,
      };

      doCollection(params).then((res) => {
        if (res.code == "2000") {
          this.$message({
            type: "success",
            message: val.collFalg == true ? "取消收藏成功" : "收藏成功",
          });
          getCollegeDetail({
            id: this.$route.query.id,
          }).then((res) => {
            if (res.code == "2000") {
              this.dataInfo = res.data;
              this.dataInfo.safetyIndex = Number(this.dataInfo.safetyIndex);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.collegeBox {
  width: 100%;
  background-color: #f5f7fa;
  padding-bottom: 84px;
}

.topban {
  width: 100%;
  height: 140px;
  background: url('../../assets/professionalList.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
}

.topban_left {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.topBox {
  width: 1200px;
  margin: -61px auto 20px;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 10px 24px 18px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  position: relative;

  .collect {
    position: absolute;
    right: 20px;
    top: 10px;
    height: 30px;
    line-height: 30px;
    background: #22C582;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    width: 69px;
    cursor: pointer;

    i {
      font-size: 18px;
      vertical-align: -2px;
      margin-left: 2px;
    }
  }

  .img {
    width: 70px;
    height: auto;
    display: block;
    margin-right: 21px;
  }

  .topinfo {
    .infotxt {
      margin-bottom: 10px;
      margin-top: 10px;

      font {
        font-weight: bold;
        font-size: 18px;
        color: $themeColor;
        margin-right: 12px;
        height: 24px;
        line-height: 24px;
        display: inline-block;
        cursor: pointer;
      }

      span {
        display: inline-block;
        height: 24px;
        padding: 0 9px;
        font-size: 14px;
        line-height: 24px;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 10px;
        border-radius: 4px;
      }

      span:nth-of-type(1) {
        background-color: #B0CBFF;
      }

      span:nth-of-type(2) {
        background-color: #9EE0D3;
      }

      span:nth-of-type(3) {
        background-color: #FBB282;
      }

      span:nth-of-type(4) {
        background-color: #9EA5E0;
      }
      span:nth-of-type(5) {
        background-color: rgba(245, 168, 168, 1);
      }
    }

    p:nth-of-type(2) {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
}

.locaBox {
  width: 1200px;
  background: #FFFFFF;
  border-radius: 12px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

  .title {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    position: relative;
    padding-left: 14px;
    margin-bottom: 20px;
  }

  .title::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: 24px;
    background-color: $themeColor;
    border-radius: 2px;
  }

  .el-rate {
    height: 30px;
    margin-bottom: 6px;
  }

  >>>.el-rate__icon {
    font-size: 30px;
  }

  .content {
    margin-bottom: 30px;

    p {
      font-size: 14px;
      color: #333333;
      line-height: 26px;
      color: $themeColor;
      text-decoration-line: underline;
      cursor: pointer;
    }

    p:nth-of-type(1) {
      margin-bottom: 6px;
    }
  }

  .mapContent {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;

    .mapTxt {
      width: 372px;

      p {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 26px;
      }
    }

    .map {
      width: 370px;
      height: 370px;

      .bm-view {
        width: 100%;
        height: 370px;
      }
    }
  }
}

.introduce {
  span {
    margin-right: 53px;
  }
}

.introBox {
  p {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
}

.extendedBox {
  margin-bottom: 20px;

  div:after {
    content: '';
    display: block;
    clear: both;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    width: 50%;
    float: left;

    span {
      display: block;
      text-align: left;

      >font {
        text-decoration-line: underline;
        cursor: pointer;
        color: #5574D8;
      }
    }
  }

  p:nth-of-type(2n) {
    float: right;
  }
}

.extendedBox::after {
  content: '';
  display: block;
  clear: both;
}

.locaBox .el-rate {
  display: inline-block;
}

.links {
  margin-bottom: 15px;
}
</style>