
import request from '@utils/request';

// 院校排名
export function getRanklist(data) {
  return request({
    url: '/mv/cmsRank/list',
    method: 'post',
    data
  });
}

export function getRank(data) {
  return request({
    url: `/mv/cmsRank/list`,
    method: 'post',
    data
  });
}
// 学校排名
export function getRankUniversity(data) {
  return request({
    url: `/mv/cmsRank/universityRank`,
    method: 'post',
    data
  });
}
// 学院排名
export function getcollegeRank(data) {
  return request({
    url: `/mv/cmsRank/collegeRank`,
    method: 'post',
    data
  });
}
