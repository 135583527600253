import request from '@utils/request';

// 登录-密码登录
export function login(data) {
  return request({
    url: '/mv/cmsUser/smsCodeLogin',
    method: 'post',
    data
  });
}
// 登录-短信登录
export function sendSms(data) {
  return request({
    url: '/mv/sms/send',
    method: 'post',
    data
  });
}
export function cmsUserDetail(data) {
  return request({
    url: '/mv/cmsUser/detail',
    method: 'post',
    data
  });
}
export function cmsUserUpdate(data) {
  return request({
    url: '/mv/cmsUser/update',
    method: 'post',
    data
  });
}

