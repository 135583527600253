<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="universityRanking">
    <headerTitle
      title="首页"
      :titles="['院校排名']"
      :showBtn="true"
      :translate="true"
      @back="back"
      :isBack='isBack'
    ></headerTitle>
    <div class="flex-justify-center">
      <div>
        <div class="condition">
          <div>
            <span>搜索：</span>
            <el-input
              size="medium"
              placeholder="请输入学校关键字"
              v-model="searchKey"
              class="input-with-button"
            >
              <template slot="append">
                <el-button size="medium" class="el-button-blue" @click="search">
                  搜索
                </el-button>
              </template>
            </el-input>
            <el-button @click="showContrast" style="float: right;width: 122px;height: 30px;padding: 0;text-align: center;color:#Fff;" round >排名对比</el-button>
          </div>
          <!-- <div class="tab-box" v-for="(item, index) in searchList" :key="index">
            <div class="flex-center" v-show="item.title">
              <span>{{ item.title }}：</span>
              <div class="flex-wrap tab-search">
                <p v-for="(items, i) in item.list" :key="i" :class="items == tabList[index] ? 'tab-active' : ''" @click="toSearch(items, index)">{{ items.name }}</p>
              </div>
            </div>
          </div> -->
          <div class="searchBox">
            <!-- <div class="project">
              <p>分类：</p>
              <el-radio-group v-model="rankType" @change="searchType">
                <el-radio-button
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.value"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div> -->
            <div class="project">
              <p>平台：</p>
              <el-radio-group v-model="rankPlatform" @change="changePlatform">
                <el-radio-button
                  v-for="(item, index) in ptlist"
                  :key="index"
                  :label="item.value"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div class="project">
              <p>年份：</p>
              <el-radio-group v-model="rankYear" @change="search">
                <el-radio-button
                  v-for="(item, index) in years"
                  :key="index"
                  :label="item.name"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div class="project" v-if=" isMain">
              <p>国家：</p>
              <el-radio-group v-model="area" :key="radioKey">
                <el-radio-button
                  v-for="(item, index) in areaList"
                  :key="index"
                  :label="item.name"
                  @click.native="Change(item.name)"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </div>
          <div class="toptitles" v-if="isCollage || isMajor">
            <span>当前学校：</span>
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              style="display: inline-block"
            >
              <el-breadcrumb-item
                v-for="(item, index) in titleList"
                :key="index"
                >{{ item.name }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
          <!--  -->
        </div>
        <div class="list">
          <el-table
            :data="tableData"
            style="width: 100%"
            @row-click="toDetail"
            v-if="isMain"
          >
            <el-table-column
              prop="rankNo"
              label="排名"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column prop="name" label="学校名称" width="400">
              <template slot-scope="scope">
                <div class="flex college-name">
                  <img :src="scope.row.universityLogo" alt="" />
                  <div>
                    <p>{{ scope.row.nameCn }}</p>
                    <span>{{ scope.row.nameEn }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="area"
              label="国家地区"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="programCount"
              label="收录专业数"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="趋势" width="170">
              <template slot-scope="scope">
                <p style="display: flex; align-items: center">
                  <span>较上年</span>
                  <img
                    style="margin-left: 6px"
                    v-if="-scope.row.rankGap == 0"
                    src="../../assets/trend1.png"
                    alt=""
                  />
                  <img
                    style="margin-left: 6px"
                    v-else-if="-scope.row.rankGap <0"
                    src="../../assets/trend2.png"
                    alt=""
                  />
                  <img
                    style="margin-left: 6px"
                    v-else-if="-scope.row.rankGap>0"
                    src="../../assets/trend3.png"
                    alt=""
                  />
                  <span
                    style="
                      margin-left: 10px;
                      display: inline-block;
                      font-size: 12px;
                    "
                    >{{ -scope.row.rankGap }}</span
                  >
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="tableDatas" style="width: 100%" v-if="isCollage"  @row-click="toMajor">
            <el-table-column
              prop="rankNo"
              label="排名"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column prop="name" label="学院名称" width="400">
              <template slot-scope="scope">
                <div class="flex college-name">
                  <div>
                    <p>{{ scope.row.nameCn }}</p>
                    <span>{{ scope.row.nameEn }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="programCount"
              label="项目数"
              align="center"
            ></el-table-column>
            <el-table-column prop="name" label="趋势" width="170">
              <template slot-scope="scope">
                <p style="display: flex; align-items: center">
                  <span>较上年</span>
                  <img
                    style="margin-left: 6px"
                    v-if="-scope.row.rankGap == 0"
                    src="../../assets/trend1.png"
                    alt=""
                  />
                  <img
                    style="margin-left: 6px"
                    v-else-if="-scope.row.rankGap <0"
                    src="../../assets/trend2.png"
                    alt=""
                  />
                  <img
                    style="margin-left: 6px"
                    v-else-if="-scope.row.rankGap>0"
                    src="../../assets/trend3.png"
                    alt=""
                  />
                  <span
                    style="
                      margin-left: 10px;
                      display: inline-block;
                      font-size: 12px;
                    "
                    >{{ -scope.row.rankGap }}</span
                  >
                </p>
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="majorList" style="width: 100%" v-if="isMajor" @row-click="toMajorDetail">
            <el-table-column
              prop="programNameCn"
              label="项目名称"
              align="center"
            >
          <template slot-scope="scope">
            <div class="flex college-name" style="justify-content: center;">
                  <div>
                    <p>{{ scope.row.programNameCn }}</p>
                    <span>{{ scope.row.programNameEn }}</span>
                  </div>
                </div>
            </template>
          </el-table-column>
            <el-table-column
              prop="Majorinner"
              label="专业排名"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 项目排名对比弹窗 -->
    <projectRankingComparison
      :year="rankYear"
      :yearList="years"
      :show.sync="comparisonShow"
    ></projectRankingComparison>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import { getRankUniversity, getcollegeRank } from 'api/rank.js';
import { getMajorRankList } from 'api/libraryList.js';
import { dicListByCode } from 'api/index.js';
import projectRankingComparison from './components/projectRankingComparison.vue';
export default {
  name: 'UniversityRanking',
  components: {
    headerTitle,
    projectRankingComparison
  },
  data() {
    return {
      searchKey: '',
      pageNum: 1,
      pageSize: 10,
      tableData: [],
      typeList: [
        {
          name: '专业',
          value: 1
        },
        {
          name: '学校',
          value: 0
        }
      ],
      ptlist: [
        {
          value: 0,
          name: 'QS'
        },
        {
          value: 1,
          name: 'U.S.News'
        },
        {
          value: 2,
          name: 'THE'
        },
        {
          value: 3,
          name: 'Ruanke'
        }
      ],
      rankType: null,
      rankPlatform: null,
      area: '',
      areaList: [],
      radioKey: '',
      showArea: false,
      rankYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear().toString(),
      titleList: [],
      isMain: true,
      isCollage: false,
      isMajor: false,
      comparisonShow: false,
      tableDatas: [],
      universityId: "",
      tableDataProgram: [],
      isBack: false,
      majorList: []
    };
  },
  computed: {
    years() {
      const startYear = 2022;
      const endYear = this.currentYear;
      const yearsArray = [];
      for (let year = startYear; year <= endYear; year++) {
        yearsArray.push({ name: year });
      }
      return yearsArray;
    }
  },
  methods: {
    search() {
      this.pageNum = 1;
      if (this.isMain) {
        this.getRank();
      } else if (this.isCollage) {
        this.getCollage();
      }
    },
    changePlatform(val) {
      if (val == 0) {
        this.getCodeList('QSs');
      } else if (val == 1) {
        this.getCodeList('UsNews');
      } else {
        this.getCodeList('THEs');
      }
      this.area = '';
      this.rankYear = this.currentYear;
      this.search();
    },
    searchType() {
      this.showArea = this.rankType === 0;
      if (!this.showArea) {
        this.areaId = '';
      }
      this.rankPlatform = 0;
      this.getRank();
    },
    getRank() {
      const data = {
        ifPage: true,
        pageSize: 999,
        pageNum: this.pageNum,
        rankPlatform: this.rankPlatform,
        area: this.area,
        searchKey: this.searchKey,
        rankYear: this.rankYear
      };
      getRankUniversity(data)
        .then((res) => {
          if (res.code == 2000) {
            // this.$store.commit('setMenu',res.data)
            this.tableData = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.msg
          });
        });
    },
    getCodeList(codes) {
      dicListByCode({ code: codes })
        .then((res) => {
          if (res.code == '2000') {
            this.areaList = res.data;
            if (this.rankPlatform == 1) {
              this.area = this.areaList[0].name;
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((error) => {
          console.error('An error occurred:', error);
          this.$message({
            type: 'error',
            message: '请求过程中发生错误，请稍后再试。'
          });
        });
    },

    Change(val) {
      this.area = this.area == val ? '' : val;
      this.radioKey = !this.radioKey;
      this.search();
    },
    toDetail(row) {
      this.isMain = false;
      this.isCollage = true;
      this.titleList.push({ name: row.nameCn });
      this.universityId = row.universityId;
      this.getCollage();
      this.isBack = true;
    },
    toMajor(row) {
      this.isMajor = true;
      this.titleList.push({ name: row.nameCn });
      this.isCollage = false;
      this.getMajorList(row.collegeId);
    },
    getCollage() {
      const data = {
        ifPage: true,
        pageSize: 999,
        pageNum: this.pageNum,
        rankPlatform: this.rankPlatform,
        area: this.area,
        searchKey: this.searchKey,
        rankYear: this.rankYear,
        universityId: this.universityId
      };
      getcollegeRank(data)
        .then((res) => {
          if (res.code == 2000) {
            // this.$store.commit('setMenu',res.data)
            this.tableDatas = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.msg
          });
        });
    },
    // 项目
    getMajorList(id) {
      const data = {
        universityId: this.universityId,
        collegeId: id,
        ifPage: true,
        pageSize: 10,
        pageNum: this.pageNum,
        rankYear: this.rankYear
      };
      getMajorRankList(data).then(res => {
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.majorList = res.data.list || [];
          this.majorList.map(item => {
            item.Majorinner = item.majors.map(items => items.majorRankStr).join('、');
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    toMajorDetail(row) {
      this.$router.push({
        path: `/professionalDetails?id=${row.id}&schoolId=${this.universityId}`
      });
    },
    back() {
      if (this.isMain) {
          this.isBack = false;
          this.$router.go(-1);
        } else {
          if (this.isCollage) {
            this.isMain = true;
            this.isCollage = false;
            this.titleList = [];
            this.tableDatas = [];
          } else if (this.isMajor) {
            this.isCollage = true;
            this.isMajor = false;
            this.titleList.splice(-1, 1);
            this.tableDataProgram = [];
          }
        }
    },
    showContrast() {
      this.comparisonShow = true;
    }
  },
  created() {
    this.rankYear = this.$route.query.rankYear?.toString() || this.currentYear;
    this.rankType =
      this.$route.query.rankType !== undefined
        ? parseInt(this.$route.query.rankType, 10)
        : 1;
    this.rankPlatform =
      this.$route.query.rankPlatform !== undefined
        ? parseInt(this.$route.query.rankPlatform, 10)
        : 0;
        console.log( this.rankPlatform);
    this.showArea = this.rankType === 0;
    if (this.rankPlatform === 0) {
      this.getCodeList('QSs');
    } else if (this.rankPlatform === 1) {
      this.getCodeList('UsNews');
    } else {
      this.getCodeList('THEs');
    }
  },
  mounted() {
    this.getRank();
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.universityRanking {
  width: 100%;
  background: #F5F7FA url('../../assets/universityRanking.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .condition, .list {
    box-sizing: border-box;
    width: 1200px;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 20px;
    padding: 20px;
  }

  .college-name {
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }
  }

  .img-vertical {
    vertical-align: middle;
  }

  .tab-box {
    margin-top: 20px;

    p {
      padding: 0 10px;
      line-height: 26px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;
      cursor: pointer;
      color: #999999;
    }

    .tab-active {
      background: #FFEFE6;
      color: $themeColor;
    }
  }

  .input-with-button {
    :deep .el-input__inner {
      border-right: 0;
    }

    width: 370px;
  }

  .el-button-blue {
    background: $themeColor;
    color: #fff;
  }
}

.searchBox {
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  padding: 20px 0 0;
  margin: 0 auto 0;
  position: relative;
  z-index: 2;
}

.project {
  display: flex;
  align-items: center;
  margin-bottom: 23px;

  p {
    font-size: 14px;
    color: #333333;
  }

  .el-radio-button {
    margin-right: 10px;
    border: none;
  }

  >>>.el-radio-button__inner {
    background: #EEEEEE;
    height: 26px;
    border-radius: 6px 6px 6px 6px;
    color: #999999;
    background-color: #eee;
    font-size: 14px;
    padding: 4px 8px;
    line-height: 17px;
    border: none;
  }

  >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: $themeColor;
    box-shadow: none;
    background: #FFEFE6;
  }
}

.toptitles {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    color: #333333;
  }

  >>>.el-breadcrumb__inner {
    font-size: 14px;
    color: #333333;
  }
}
.el-button{
  background-color: #FF8240 !important;
  border-color: #FF8240 !important;
}
>>>.el-radio-button:first-child .el-radio-button__inner{
  border-left: none !important;

}
</style>