<template>
  <div class="leftmenu">
    <div class="userbox">
      <img :src="userInfo.headLogo" alt="">
      <p>用户：{{ userInfo.name }}</p>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      @handleSelect="handleSelect"
    >
    <div v-for="(item, index) in pageList" :key="index">
      <el-menu-item :index="item.url"  @click="routerTo(item)">
        <i><img :src="item.isActive ? item.iconAct : item.icon" alt=""></i>
        <span slot="title">{{item.name }}</span>
      </el-menu-item>
    </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  data() {
    return {
        pageList: [
          { name: "个人资料", url: "/personalData", icon: require('@/assets/lefticon1.png'), iconAct: require('@/assets/lefticon1_hover.png'), isActive: false },
          // { name: "我的礼包", url: "/myGiftbag", icon: require('@/assets/lefticon2.png'), iconAct: require('@/assets/lefticon2_hover.png'), isActive: false },
          { name: '我的收藏', url: '/myCollection', icon: require('@/assets/lefticon3.png'), iconAct: require('@/assets/lefticon3_hover.png'), isActive: false },
          { name: '我的订单', url: '/myOrder', icon: require('@/assets/lefticon4.png'), iconAct: require('@/assets/lefticon4_hover.png'), isActive: false },
          // { name: '我的档案', url: '/applicationForm', icon: require('@/assets/lefticon5.png'), iconAct: require('@/assets/lefticon5_hover.png'), isActive: false },
        ],
        imgSrc: require("@/assets/defaultIcon.png"),
        activeIndex: '',
        userInfo: {}
    };
  },
  mounted() {
    this.activeIndex = this.$route.path;
    console.log(this.$route.path, 'this.$route.path');
    this.pageList.forEach(item => {
      if (item.url === this.$route.path) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    });
    this.userInfo = localStorage.getItem('userInfo');
  },
  methods: {
    handleSelect(index, indexPath) {
      this.activeIndex = index;
      this.pageList.forEach(item => {
        item.isActive = item.url === index;
      });
    },
    routerTo(item) {
      this.$router
        .push({
          path: item.url
        })
        .then(() => {})
        .catch(() => {});
    },

  },
};
</script>

<style  lang='stylus' >
@import '~css/stylus.styl';
.leftmenu{
    width: 230px;
    height: 100%;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    margin: 27px 0;
    padding: 20px 0 0;
    text-align: center;
    min-height: 645px;
}
.el-menu{
   border-right: none;
}
.el-menu-item {
    border-right: none;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    color: #999999;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    i{
      img{
        width:22px;
        height:22px;
        margin-right: 10px;
        vertical-align: -5px;
      }
    }
}
.el-menu-item:focus, .el-menu-item:hover{
  background: linear-gradient( 270deg, #FFFFFF 0%, #FFEFE6 100%);
}
.el-menu-item.is-active{
  background: linear-gradient( 270deg, #FFFFFF 0%, #FFEFE6 100%);
  color: $themeColor;
}
.el-menu-item{
  justify-content:flex-start;
  padding-left: 42px !important ;
}
.userbox{
    margin-bottom: 20px;
    p{
      font-size: 14px;
      color: #666666;
      margin-top: 10px
    }
    img{
      width:40px;
      height:40px;
      border-radius: 50%;
    }
}
</style>